/* -----
Basic Mixin'
----- */
/* -----
Hover
----- */
/* -----
Paths
----- */
/* -----
Colors
----- */
/* -----
CSS Easings
----- */
/* -----
Media Queries
----- */
/* -----
Grid
----- */
/* -----
Typography, WebFonts
----- */
.row {
  zoom: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.column {
  display: block;
  float: left;
}

.col-1 {
  width: 8.3333333333%;
}

.col-2 {
  width: 16.6666666667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.3333333333%;
}

.col-5 {
  width: 41.6666666667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.3333333333%;
}

.col-8 {
  width: 66.6666666667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.3333333333%;
}

.col-11 {
  width: 91.6666666667%;
}

.col-12 {
  width: 100%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  display: block;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

a {
  background: transparent;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type=button],
input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: 700;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 62.5%;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "macho", sans-serif;
  color: #231f20;
  background: #ffffff;
  font-size: 1.4rem;
  line-height: 1.3;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
}
body.is-loading {
  overflow: hidden;
  visibility: hidden;
}

.App {
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 670px) {
  .App {
    padding: 0 25px;
  }
}

#barba-wrapper .barba-container {
  background: #f8f8f8;
  padding-bottom: 120px;
  margin: 0 auto;
  max-width: 1900px;
}
#barba-wrapper .barba-container > section > .container {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) {
  #barba-wrapper .barba-container > section > .container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (min-width: 1200px) {
  #barba-wrapper .barba-container > section > .container {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.container {
  width: 100%;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
  position: relative;
}
@media screen and (min-width: 670px) {
  .container {
    padding: 0 60px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    width: 90%;
  }
}
@media screen and (min-width: 992px) {
  .container {
    width: 84%;
  }
}
.container--full {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .container--full {
    width: 98%;
  }
}
@media screen and (min-width: 1320px) {
  .container--full {
    width: 93%;
    max-width: 1750px;
  }
}

.hidden {
  display: none !important;
}

.is-visible {
  display: block;
}

svg {
  display: block;
}

.icon {
  font-style: normal;
  display: block;
}
.icon-close {
  font-size: 3rem;
  line-height: 0.8em;
  transform-origin: center center;
  transform: rotate(45deg);
}

.loading_data {
  text-align: center;
}
.loading_data img {
  display: inline-block;
  margin: 0 auto;
}

.shadowbox > .inner {
  padding: 40px;
}
.shadowbox > .inner h1 {
  font-size: 2.4rem;
  margin-bottom: 0;
}

h1, h2, h3, .Page-account .Login form fieldset legend, .Page-creation .Login form fieldset legend, .Page-contact form[name=form_contact] .row .col legend, h4 {
  font-family: "orpheuspro", serif;
  font-weight: 500;
  line-height: 1.1;
}
h1.lined:after, h2.lined:after, h3.lined:after, .Page-account .Login form fieldset legend.lined:after, .Page-creation .Login form fieldset legend.lined:after, .Page-contact form[name=form_contact] .row .col legend.lined:after, h4.lined:after {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  margin-top: 15px;
  background: #ddb8a2;
}
@media screen and (min-width: 670px) {
  h1.lined:after, h2.lined:after, h3.lined:after, .Page-account .Login form fieldset legend.lined:after, .Page-creation .Login form fieldset legend.lined:after, .Page-contact form[name=form_contact] .row .col legend.lined:after, h4.lined:after {
    height: 5px;
    margin-top: 30px;
    width: 80px;
  }
}
h1.lined.lined--green:after, h2.lined.lined--green:after, h3.lined.lined--green:after, .Page-account .Login form fieldset legend.lined.lined--green:after, .Page-creation .Login form fieldset legend.lined.lined--green:after, .Page-contact form[name=form_contact] .row .col legend.lined.lined--green:after, h4.lined.lined--green:after {
  background: #7e9a68;
}
h1.lined.lined--white:after, h2.lined.lined--white:after, h3.lined.lined--white:after, .Page-account .Login form fieldset legend.lined.lined--white:after, .Page-creation .Login form fieldset legend.lined.lined--white:after, .Page-contact form[name=form_contact] .row .col legend.lined.lined--white:after, h4.lined.lined--white:after {
  background: #ffffff;
}
h1.lined.lined--black:after, h2.lined.lined--black:after, h3.lined.lined--black:after, .Page-account .Login form fieldset legend.lined.lined--black:after, .Page-creation .Login form fieldset legend.lined.lined--black:after, .Page-contact form[name=form_contact] .row .col legend.lined.lined--black:after, h4.lined.lined--black:after {
  background: #231f20;
}
h1.txt-center, h2.txt-center, h3.txt-center, .Page-account .Login form fieldset legend.txt-center, .Page-creation .Login form fieldset legend.txt-center, .Page-contact form[name=form_contact] .row .col legend.txt-center, h4.txt-center {
  text-align: center;
}
h1.txt-center:after, h2.txt-center:after, h3.txt-center:after, .Page-account .Login form fieldset legend.txt-center:after, .Page-creation .Login form fieldset legend.txt-center:after, .Page-contact form[name=form_contact] .row .col legend.txt-center:after, h4.txt-center:after {
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: 5rem;
}
@media screen and (min-width: 670px) {
  h1 {
    font-size: 6.5rem;
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 1460px) {
  h1 {
    font-size: 8rem;
    margin-bottom: 80px;
  }
}

h2 {
  font-size: 3.2rem;
  margin-bottom: 40px;
}
@media screen and (min-width: 670px) {
  h2 {
    font-size: 4.2rem;
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 1200px) {
  h2 {
    font-size: 5rem;
    margin-bottom: 80px;
  }
}

h3, .Page-account .Login form fieldset legend, .Page-creation .Login form fieldset legend, .Page-contact form[name=form_contact] .row .col legend {
  font-size: 2.4rem;
  margin-bottom: 20px;
}
@media screen and (min-width: 992px) {
  h3, .Page-account .Login form fieldset legend, .Page-creation .Login form fieldset legend, .Page-contact form[name=form_contact] .row .col legend {
    font-size: 3rem;
    margin-bottom: 30px;
  }
}

p, .noresult {
  color: #727272;
  line-height: 1.6;
}
p a, .noresult a {
  text-decoration: underline;
}

.noresult {
  max-width: 600px;
  margin: 20px auto;
}

.wswyg--content p, .wswyg--content .noresult {
  margin: 0px;
}
.wswyg--content * + * {
  margin-top: 30px;
}
.wswyg--content ul {
  padding-left: 40px;
}
.wswyg--content ul li + li {
  margin-top: 5px;
}

.no-results {
  display: block;
  margin-top: 60px;
  font-size: 3.2rem;
  font-family: "orpheuspro", serif;
  opacity: 0.3;
}

a, button {
  text-decoration: none;
  outline: none;
  color: inherit;
}

.btn {
  padding: 12px 30px;
  display: inline-block;
  font-family: "orpheuspro", serif;
  border-radius: 0px;
  color: #ffffff;
  font-size: 1.8rem;
  font-style: italic;
  margin-top: 50px;
  position: relative;
  overflow: hidden;
  border: 1px solid #000000;
  border-radius: 3px;
  text-align: center;
}
.btn:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #000;
  z-index: 1;
  transition: transform 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
a:hover .btn:after, .btn:hover:after {
  transform: translateY(-100%);
}
.btn-min {
  padding: 6px 20px;
  font-size: 1.4rem;
}
.btn-big {
  padding: 16px 50px;
  font-size: 2rem;
}
.btn-lined {
  background: 0 !important;
  color: #231f20;
}
.btn-lined:after {
  transform: translateY(100%);
}
a:hover .btn-lined:after, .btn-lined:hover:after {
  transform: translateY(0%);
}
.btn-green {
  background: #7e9a68 !important;
  border: 0;
}
.btn-green:after {
  display: none;
}
.btn-green .btn-txt {
  color: #ffffff !important;
  mix-blend-mode: inherit !important;
}
.btn-rose {
  background: #ddb8a2;
  border: 0;
}
.btn-rose:after {
  display: none;
}
.btn-rose .btn-txt {
  mix-blend-mode: inherit !important;
}
.btn-white {
  background: #ddb8a2;
}
.btn-white .btn-txt {
  color: #231f20 !important;
  mix-blend-mode: inherit !important;
}
.btn-white:after {
  background: #ffffff;
}
.btn-red {
  border-color: #f01414;
}
.btn-red .btn-txt {
  mix-blend-mode: inherit !important;
}
.btn-red:hover .btn-txt {
  color: #f01414 !important;
}
.btn-red:after {
  background: #f01414;
}
.btn .btn-txt {
  position: relative;
  z-index: 10;
  color: #fff;
  mix-blend-mode: difference;
}
.chrome .btn .btn-txt {
  mix-blend-mode: normal;
  color: #ffffff;
  transition: color 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.chrome .btn:hover .btn-txt {
  color: #231f20;
}
.chrome .btn.btn-lined .btn-txt {
  color: #231f20;
}
.chrome .btn.btn-lined:hover .btn-txt {
  color: #ffffff;
}

.link-back {
  display: inline-block;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .link-back {
    margin-bottom: 20px;
  }
}
.link-back:hover .arrow {
  transform: translateX(-10px);
}
.link-back > * {
  display: inline-block;
  vertical-align: middle;
}
.link-back .arrow {
  margin-right: 10px;
  margin-top: -1px;
  transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.link-back .arrow svg {
  display: block;
}
.link-back .arrow svg > * {
  fill: transparent;
  stroke: #231f20;
  stroke-width: 1px;
}
.link-back .link-txt {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 0.9rem;
}

a.link {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
a.link:hover:after {
  transform: scaleX(0.5);
}
a.link span {
  display: inline-block;
}
a.link:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #231f20;
  transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.Topbar {
  position: relative;
  z-index: 100;
  background: #000000;
}
@media screen and (min-width: 768px) {
  .Topbar {
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.Topbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  padding-top: 10px;
}
@media screen and (min-width: 768px) {
  .Topbar .container {
    padding: 7px 60px 12px;
  }
}
.Topbar p, .Topbar .noresult {
  font-size: 1.3rem;
  line-height: 1.4;
  color: #ffffff;
}
.Topbar p > a, .Topbar .noresult > a {
  color: white;
  font-weight: bold;
  text-decoration: none !important;
}
.Topbar-close {
  color: #ffffff;
  transform-origin: center center;
  transition: transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin-left: 20px;
}
.Topbar-close:hover {
  transform: rotate(90deg) translateX(3px);
}

.Header {
  position: relative;
  z-index: 10;
  background: #ffffff;
}
.Header-container {
  position: relative;
  z-index: 1000;
}
@media screen and (min-width: 1200px) {
  .Header--absolute .Header-container {
    position: absolute;
    top: 0;
    left: 25px;
    right: 25px;
  }
}
.Header--absolute .Header {
  background: transparent;
}
.Header--light .Header {
  width: 100%;
  background: transparent;
}
@media screen and (min-width: 1200px) {
  .Header--light .Header a {
    color: #ffffff;
  }
}
@media screen and (min-width: 1200px) {
  .Header--light .Header a:after {
    background: white !important;
  }
}
@media screen and (min-width: 1200px) {
  .Header--light .Header .Header-logo svg > * {
    fill: #ffffff;
  }
}
@media screen and (min-width: 1200px) {
  .Header--light .Header .Menu-toggle .icon .line {
    background: #ffffff;
  }
}
@media screen and (min-width: 1200px) {
  .Header--light .Header .Menu-search form {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
@media screen and (min-width: 1200px) {
  .Header--light .Header .Menu-search form input {
    color: #ffffff;
  }
}
@media screen and (min-width: 1200px) {
  .Header--light .Header .Menu-search form input::placeholder {
    color: #ffffff;
  }
}
@media screen and (min-width: 1200px) {
  .Header--light .Header .Menu-search form button .icon svg > * {
    fill: #ffffff;
  }
}
@media screen and (min-width: 1200px) {
  .Header--light .Header .Socials a svg > * {
    fill: #ffffff;
  }
}
@media screen and (min-width: 1200px) {
  .Header--light .Header .Menu-langs {
    color: #ffffff;
  }
}
.Header .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 80px;
  position: initial;
}
@media screen and (max-width: 1199px) {
  .Header .container {
    padding: 0 20px;
  }
}
@media screen and (min-width: 1200px) {
  .Header .container {
    height: 130px;
  }
}
.Header-logo {
  margin-right: 20px;
}
@media screen and (min-width: 1320px) {
  .Header-logo {
    margin-right: 40px;
  }
}
.Header-logo h1 {
  margin: 0;
}
.Header-logo svg {
  width: 100px;
  height: 40px;
}
@media screen and (min-width: 1200px) {
  .Header-logo svg {
    width: 130px;
    height: 50px;
  }
}
@media screen and (min-width: 1700px) {
  .Header-logo svg {
    width: 150px;
    height: 55px;
  }
}
.Header .Menu ul li a, .Header .Account__link {
  position: relative;
  text-align: center;
}
.Header .Menu ul li a:after, .Header .Account__link:after {
  content: "";
  display: block;
  position: absolute;
  pointer-events: none;
  top: 80%;
  left: 0;
  right: 0;
  height: 1px;
  background: #231f20;
  transform: translateY(5px);
  opacity: 0;
  transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media screen and (min-width: 1200px) {
  .Header .Menu ul li a:after, .Header .Account__link:after {
    top: 100%;
  }
}
.Header .Menu ul li a:hover:after, .Header .Account__link:hover:after {
  transform: translateY(0px);
  opacity: 1;
}
.Header .Menu ul li a.logout, .Header .Account__link.logout {
  opacity: 0.5;
}
.Header .Menu ul li.active a:after {
  transform: translateY(0px);
  opacity: 1;
}

.Menu > ul, .Menu-sub {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 1199px) {
  .Menu ul > li {
    width: 100%;
    margin: 0 auto 10px !important;
    text-align: center;
  }
}
.Menu ul > li + li {
  margin-left: 20px;
}
@media screen and (min-width: 1320px) {
  .Menu ul > li + li {
    margin-left: 30px;
  }
}
@media screen and (min-width: 1700px) {
  .Menu ul > li + li {
    margin-left: 40px;
  }
}
.Menu ul > li > a {
  font-size: 2rem;
  font-weight: bold;
  font-family: "orpheuspro", serif;
}
@media screen and (min-width: 768px) {
  .Menu ul > li > a {
    font-size: 3rem;
  }
}
@media screen and (min-width: 1200px) {
  .Menu ul > li > a {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 1700px) {
  .Menu ul > li > a {
    font-size: 1.6rem;
  }
}
.Menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
@media screen and (min-width: 1200px) {
  .Menu-toggle {
    display: none;
  }
}
.Menu-toggle .icon .line {
  display: block;
  height: 1px;
  width: 20px;
  background: #231f20;
  border-radius: 2px;
}
.Menu-toggle .icon .line + .line {
  margin-top: 4px;
}
.Menu-toggle .icon .line:last-child {
  width: 15px;
}
.Menu-container {
  flex-grow: 2;
}
@media screen and (max-width: 1199px) {
  .Menu-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    background: #ffffff;
    height: calc(100vh - 173px);
    z-index: 100;
    overflow: auto;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    visibility: hidden;
    pointer-events: none;
  }
}
@media screen and (max-width: 767px) {
  .Menu-container {
    height: calc(100vh - 130px);
  }
}
.showMenu .Menu-container {
  visibility: visible !important;
  pointer-events: all;
}
.Menu-container .inner {
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  width: 100%;
  opacity: 0;
  transform: translateY(40px);
  transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media screen and (min-width: 1200px) {
  .Menu-container .inner {
    display: flex;
    background: transparent;
    height: auto;
    top: 0;
    left: 0;
    width: auto;
    position: relative;
    padding: 0;
    opacity: 1;
    transform: translateY(0px);
  }
}
.showMenu .Menu-container .inner {
  opacity: 1;
  transform: translateY(0px);
}
.Menu-sub {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 10px;
}
@media screen and (min-width: 1320px) {
  .Menu-sub {
    margin-left: 30px;
  }
}
@media screen and (min-width: 1700px) {
  .Menu-sub {
    margin-left: 40px;
  }
}
@media screen and (max-width: 1199px) {
  .Menu-sub {
    margin: 20px 40px 0px;
  }
}
.Menu-sub > * {
  margin-right: 20px;
}
@media screen and (max-width: 1199px) {
  .Menu-sub > * {
    width: 100%;
    margin: 0 auto 20px;
  }
}
.Menu-sub > *:last-child {
  margin-right: 0;
}
.Menu-sub .Account__link:after {
  margin-top: 4px;
}
.Menu-search form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  max-width: 300px;
  margin: 0 auto;
}
.Menu-search form input {
  border: 0;
  font-size: 1.3rem;
  padding: 6px 12px;
  text-overflow: ellipsis !important;
  min-height: 0;
  background: transparent;
  flex-grow: 2;
}
.Menu-search form button {
  border: 0;
  background: 0;
  width: 30px;
  min-height: 0 !important;
  padding: 0 !important;
  background: 0 !important;
}
.Menu-search form button .icon svg {
  margin: 0 auto;
}
.Menu-langs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}
.Menu-langs > li {
  font-weight: bold;
  margin-right: 10px;
}
.Menu-langs > li:last-child {
  margin-right: 0;
}
.Menu-langs > li a {
  opacity: 0.1;
}

body.showMenu > header nav {
  display: block;
}

.Footer .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .Footer .container {
    padding-top: 80px;
    padding-bottom: 80px;
    justify-content: flex-start;
    text-align: left;
  }
}
@media screen and (min-width: 1200px) {
  .Footer .container {
    justify-content: space-between;
  }
}
.Footer .container > *:not(:last-child) {
  width: 100%;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .Footer .container > *:not(:last-child) {
    width: calc(33.333% - 30px);
    margin-right: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .Footer .container > *:not(:last-child) {
    width: auto;
  }
}
.Footer-nav ul li + li {
  margin-top: 15px;
}
.Footer-nav ul li a {
  color: #727272;
}
@media screen and (max-width: 767px) {
  .Footer-logo {
    margin: 0 auto 40px !important;
    width: auto !important;
  }
}
.Footer-logo svg {
  width: 100px;
  height: 40px;
}
@media screen and (min-width: 768px) {
  .Footer-logo svg {
    width: 130px;
    height: 50px;
  }
}
@media screen and (min-width: 1700px) {
  .Footer-logo svg {
    width: 150px;
    height: 55px;
  }
}
.Footer-contact p, .Footer-contact .noresult {
  color: #231f20;
}
.Footer-contact p a, .Footer-contact .noresult a {
  text-decoration: none;
}
.Footer-contact .Contact-phone, .Footer-contact .Contact-mail {
  font-size: 2.4rem;
  line-height: 1.2;
  display: inline-block;
  position: relative;
  font-family: "orpheuspro", serif;
  color: #231f20;
  margin-top: 15px;
}
.Footer-contact .Contact-mail {
  position: relative;
}
.Footer-contact .Contact-mail:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 1px;
  background: #231f20;
  margin-top: -3px;
}
.Footer-paiements {
  color: #231f20;
}
.Footer-paiements img {
  display: block;
  max-width: 120px;
  opacity: 0.5;
  margin: 20px auto 0px;
}
@media screen and (min-width: 768px) {
  .Footer-paiements img {
    margin: 20px 0px 0px;
  }
}
.Footer-more p, .Footer-more .noresult, .Footer-more a {
  color: #727272;
}
.Footer-more .Made {
  margin-top: 20px;
}
.Footer-more .Made a {
  display: inline-block;
}
.Footer .Socials {
  margin-bottom: 20px;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .Footer .Socials {
    justify-content: flex-start;
  }
}

label {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  label {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
}

input.form-control, textarea.form-control {
  padding: 0 15px;
  color: #231f20;
  border: 0;
  height: 50px;
  margin: 0;
  font-size: 1.6rem;
  width: 100%;
  border: 1px solid #dfdfdf;
}
@media screen and (min-width: 768px) {
  input.form-control, textarea.form-control {
    height: 70px;
    font-size: 2rem;
    padding: 0 30px;
  }
}

textarea.form-control {
  height: auto;
  padding: 30px;
  min-height: 200px;
}

.radios, .checkboxs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.radios input, .checkboxs input {
  margin-top: 3px;
}
.radios label, .checkboxs label {
  margin-right: 20px;
  margin-bottom: 20px;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 300;
}
@media screen and (min-width: 768px) {
  .radios label, .checkboxs label {
    font-size: 1.8rem;
    margin-right: 30px;
  }
}
.radios label span, .checkboxs label span {
  margin-left: 10px;
}

.form-group {
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .form-group {
    margin-bottom: 40px;
  }
}
.form-group .alert-error {
  display: block;
  text-align: right;
  background: #f01414;
  padding: 8px 12px;
  border-radius: 4px;
}

.form-actions {
  margin-top: 80px;
  text-align: center;
}
.form-actions .mentions {
  margin-bottom: 40px;
}

button[type=submit] {
  font-size: 2.4rem;
  color: white;
  background: #ddb8a2;
  font-style: italic;
  font-family: "orpheuspro", serif;
  border: 0;
  min-height: 50px;
  padding: 0px 40px;
  white-space: nowrap;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}
button[type=submit].btn--submit .btn__txt {
  position: relative;
  z-index: 2;
}
button[type=submit].btn--submit:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #000;
  z-index: 0;
  transform: translateY(100%);
  transition: transform 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
a:hover button[type=submit].btn--submit:after, button[type=submit].btn--submit:hover:after {
  transform: translateY(0%);
}
@media screen and (min-width: 768px) {
  button[type=submit] {
    font-size: 3rem;
    min-height: 70px;
  }
}

.chzn-container {
  border: 1px solid #231f20;
  box-shadow: 0;
  width: auto !important;
}
.chzn-container .search-choice-close {
  display: none !important;
}
.chzn-container.chzn-container-active a.chzn-single div > b {
  transform: rotate(180deg);
}
.chzn-container.chzn-container-active .chzn-drop {
  visibility: visible;
  pointer-events: all;
  opacity: 1;
}
.chzn-container a.chzn-single {
  padding: 0px;
  border: 0;
  background: 0;
  height: auto;
}
.chzn-container a.chzn-single span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 20px 0px 10px;
  color: #231f20;
}
.chzn-container a.chzn-single div {
  margin-right: 2px;
}
.chzn-container a.chzn-single div > b {
  background: url("../img/SVG/select-arrow.svg") no-repeat center center;
  background-size: 8px auto;
}
.chzn-container .chzn-drop {
  left: inherit !important;
  right: -1px;
  top: 100% !important;
  border: 1px solid #231f20;
  min-width: 150px !important;
  border-radius: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.chzn-container .chzn-search {
  display: none;
}
.chzn-container .chzn-results {
  padding: 0;
  margin: 0;
}
.chzn-container .chzn-results li {
  padding: 6px 12px;
}
.chzn-container .chzn-results li:hover {
  background: #231f20 !important;
  color: #ffffff !important;
}
.chzn-container .chzn-results li.result-selected, .chzn-container .chzn-results li.highlighted {
  background: #ebebeb;
  color: #231f20;
}

.form_simple .row {
  justify-content: space-between;
}
.form_simple .row > * {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .form_simple .row > * {
    max-width: calc(50% - 10px);
  }
}
.form_simple fieldset {
  padding: 0px;
  margin: 0;
  border: 0;
}
.form_simple fieldset + fieldset {
  margin-top: 40px;
}
.form_simple .fin {
  margin-top: 40px;
}
.form_simple .legend, .form_simple .Reservation-dates .info_enfants, .Reservation-dates .form_simple .info_enfants, .form_simple .Reservation-personnes .info_enfants, .Reservation-personnes .form_simple .info_enfants {
  display: block;
  margin-bottom: 10px;
}
.form_simple .select-container .tiselect {
  display: block;
  margin-bottom: 10px;
}
.form_simple .select-container .chzn-drop {
  width: 100% !important;
}
.form_simple .select-container .selects {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-wrap: nowrap;
}
.form_simple .select-container .chzn-container {
  width: 100% !important;
  height: 100%;
  border: 1px solid #dfdfdf;
  background: white;
  margin-bottom: 20px;
}
.form_simple .select-container .chzn-container .chzn-single {
  display: block;
  height: 50px;
  line-height: 50px;
  box-shadow: none !important;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .form_simple .select-container .chzn-container .chzn-single {
    height: 70px;
    line-height: 70px;
    padding: 0 30px;
  }
}
.form_simple .select-container .chzn-container .chzn-single span {
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: none;
  margin: 0;
  margin-right: 30px;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .form_simple .select-container .chzn-container .chzn-single span {
    font-size: 2rem;
  }
}
.form_simple .select-container .chzn-container .chzn-single div {
  right: 20px;
}
.form_simple .select-container .chzn-container .chzn-single div > b {
  background-size: 12px auto;
}

.Socials {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.Socials a {
  margin-right: 15px;
}
.Socials a:last-child {
  margin-right: 0;
}

.Hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  text-align: center;
  color: #ffffff;
  background: #4b362a;
  max-height: 950px;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .Hero {
    height: 75vh;
    min-height: 700px;
  }
}
@media screen and (min-width: 1200px) {
  .Hero {
    padding-top: 50px;
  }
}
@media screen and (min-width: 1460px) {
  .Hero {
    height: 90vh;
  }
}
.Hero .container {
  position: relative;
  z-index: 10;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .Hero .container {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
}
.Hero h1 span {
  display: block;
  font-size: 2.6rem;
}
.Hero h1 i {
  display: block;
  font-size: 2.4rem;
}
@media screen and (min-width: 768px) {
  .Hero h1 i {
    font-size: 4.2rem;
  }
}
.Hero .visuel-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
  opacity: 0.4;
}
.Hero .visuel-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Hero .visuel-container .visuel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.Hero .visuel-container.swiper-container .swiper-slide img {
  position: absolute;
  height: 100%;
  width: auto;
  max-width: none;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.Filters {
  position: relative;
  z-index: 100;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  margin: 40px auto 0 !important;
}
.Hero .Filters {
  margin-top: 60px !important;
  max-width: 666px;
  border: 0;
  border-radius: 3px;
  overflow: hidden;
  background: #ffffff;
}
.Hero .Filters .datepickers {
  border-right: 0;
}
@media screen and (min-width: 1200px) {
  .Hero .Filters .datepickers input {
    width: 215px !important;
  }
}
.Filters form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  border: 0;
}
@media screen and (min-width: 1200px) {
  .Filters form {
    flex-wrap: nowrap;
  }
}
.Filters .bloc_affiner {
  position: relative;
  min-height: 70px;
  flex-grow: 2;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .Filters .bloc_affiner {
    width: auto;
    min-width: 33.333%;
  }
}
@media screen and (min-width: 1200px) {
  .Filters .bloc_affiner {
    flex-grow: 0;
    min-width: 0;
  }
}
.Filters .bloc_affiner + .bloc_affiner {
  border-top: 1px solid #d3d3d3;
}
@media screen and (min-width: 768px) {
  .Filters .bloc_affiner + .bloc_affiner {
    border-top: 0;
    border-left: 1px solid #d3d3d3;
  }
}
.Filters .bloc_affiner:hover {
  z-index: 20;
}
.Filters .bloc_affiner:hover .liste_check {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.Filters .bloc_affiner .label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  font-size: 1.5rem;
  white-space: nowrap;
  padding: 0 20px;
}
@media screen and (min-width: 1460px) {
  .Filters .bloc_affiner .label {
    padding: 0 30px;
  }
}
.Filters .bloc_affiner .arrow {
  margin-left: 30px;
}
.Filters .bloc_affiner .arrow svg {
  display: block;
}
.Filters .bloc_affiner .liste_check {
  position: absolute;
  top: 100%;
  right: -1px;
  width: calc(100% + 2px);
  min-width: 250px;
  background: #f8f8f8;
  border: 1px solid #d3d3d3;
  list-style-type: none;
  padding: 20px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.Filters .bloc_affiner .liste_check li + li {
  margin-top: 10px;
}
.Filters .bloc_affiner .liste_check label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  text-transform: none;
  letter-spacing: 0;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-bottom: 0;
  cursor: pointer;
}
.Filters .bloc_affiner .liste_check label:hover div {
  opacity: 1;
}
.Filters .bloc_affiner .liste_check label input {
  margin-right: 10px;
  margin-top: 5px;
}
.Filters .bloc_affiner .liste_check label input:checked + div {
  opacity: 1;
}
.Filters .bloc_affiner .liste_check label div {
  width: auto;
  opacity: 0.3;
  font-weight: 400;
  word-wrap: normal;
}
.Filters button[type=submit] {
  width: 100%;
  flex-grow: 2;
  font-size: 3rem;
}
@media screen and (min-width: 1200px) {
  .Filters button[type=submit] {
    margin-left: auto;
    margin-right: -1px;
    margin-top: -1px;
    margin-bottom: -1px;
    border-radius: 0px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    width: auto;
    font-size: 2.4rem;
    padding: 0px 20px;
  }
}
@media screen and (min-width: 1460px) {
  .Filters button[type=submit] {
    font-size: 3rem;
    padding: 0px 40px;
  }
}
.Filters-results {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}
.Filters-results .Results-nbr {
  font-size: 3rem;
  font-family: "orpheuspro", serif;
}

.datepickers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #d3d3d3;
  width: 100%;
}
.Hero .datepickers {
  border-bottom: 0 !important;
}
@media screen and (min-width: 1200px) {
  .datepickers {
    flex-wrap: nowrap;
    width: auto;
    border-bottom: 0;
    border-right: 1px solid #d3d3d3;
  }
}
.datepickers .datepicker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .datepickers .datepicker-container {
    width: 50%;
  }
}
.datepickers .datepicker-container + .datepicker-container {
  position: relative;
}
.datepickers .datepicker-container + .datepicker-container:before {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  top: 0;
  height: 1px;
  background: #727272;
  opacity: 0.2;
}
@media screen and (min-width: 768px) {
  .datepickers .datepicker-container + .datepicker-container:before {
    content: "";
    position: relative;
    display: block;
    width: 1px;
    height: 30px;
  }
}
.datepickers input {
  width: auto;
  min-width: 0;
  width: 100% !important;
  background: 0;
  text-overflow: ellipsis;
  border: 0 !important;
}
@media screen and (min-width: 1200px) {
  .datepickers input {
    width: 145px !important;
  }
}
@media screen and (min-width: 1460px) {
  .datepickers input {
    width: 215px !important;
  }
}
.datepickers .ui-datepicker-trigger {
  min-width: 24px;
  max-width: 24px;
  margin-right: 30px;
}

.Appartments-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  list-style-type: none;
  align-items: stretch;
  margin-bottom: -60px;
}
@media screen and (min-width: 670px) {
  .Appartments-list {
    margin-right: -40px;
  }
}
@media screen and (min-width: 992px) {
  .Appartments-list {
    margin-bottom: -80px;
  }
}
.Appartments-more {
  margin-top: 80px;
  text-align: center;
}
.Appartments-more .legend, .Appartments-more .Reservation-dates .info_enfants, .Reservation-dates .Appartments-more .info_enfants, .Appartments-more .Reservation-personnes .info_enfants, .Reservation-personnes .Appartments-more .info_enfants {
  display: block;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.Appartments-more .btn {
  margin-top: 20px;
}

.Appartment.barba-container {
  padding-bottom: 0 !important;
}
.Appartment-cat {
  text-transform: uppercase;
  font-size: 1rem;
  display: block;
  margin-bottom: 5px;
  letter-spacing: 0.1em;
  font-weight: 500;
  transition: transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 150ms linear;
}
@media screen and (min-width: 670px) {
  .Appartment-cat {
    font-size: 1.2rem;
  }
}
.Appartment-header > .container {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .Appartment-header > .container {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media screen and (min-width: 1200px) {
  .Appartment-header > .container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.Appartment-header > .container .anchor {
  margin-left: 10px;
}
@media screen and (min-width: 768px) {
  .Appartment-header > .container .row {
    flex-wrap: nowrap;
  }
}
.Appartment-header .actions {
  white-space: nowrap;
  margin-top: 40px;
  border-top: 1px solid #ebebeb;
  width: 100%;
  text-align: center;
  padding-top: 40px;
}
@media screen and (min-width: 768px) {
  .Appartment-header .actions {
    text-align: right;
    margin-left: 80px;
    padding-top: 0;
    width: auto;
    border: 0;
    margin-top: 0;
  }
}
.Appartment-header .actions .listeprix {
  margin-bottom: 10px;
}
.Appartment-header .actions .btn {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .Appartment-header .actions .btn {
    margin-top: 40px;
  }
}
.Appartment-header .actions .Selection-btn {
  margin-right: 10px;
}
.Appartment-header .actions .legend, .Appartment-header .actions .Reservation-dates .info_enfants, .Reservation-dates .Appartment-header .actions .info_enfants, .Appartment-header .actions .Reservation-personnes .info_enfants, .Reservation-personnes .Appartment-header .actions .info_enfants {
  display: block;
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 1rem;
}
.Appartment-title {
  font-size: 3.4rem;
  margin-bottom: 20px;
}
@media screen and (min-width: 992px) {
  .Appartment-title {
    font-size: 5rem;
  }
}
@media screen and (min-width: 1200px) {
  .Appartment-title {
    font-size: 6rem;
  }
}
.Appartment-swiper {
  background: #4b362a;
  position: relative;
}
.Appartment-swiper .swiper-buttons {
  position: absolute;
  top: 50%;
  left: 30px;
  right: 30px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .Appartment-swiper .swiper-buttons {
    left: 60px;
    right: 60px;
  }
}
.Appartment-swiper .swiper-buttons > * {
  position: relative;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 60px;
  pointer-events: all;
  cursor: pointer;
  transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 200ms linear;
}
@media screen and (min-width: 768px) {
  .Appartment-swiper .swiper-buttons > * {
    width: 60px;
    height: 60px;
  }
}
.Appartment-swiper .swiper-buttons > *:hover {
  transform: scale(1.2);
}
.Appartment-swiper .swiper-buttons > *:hover .arrow {
  transform: translate(-50%, -50%) scale(0.7);
}
.Appartment-swiper .swiper-buttons > *.swiper-next {
  transform: rotate(180deg);
}
.Appartment-swiper .swiper-buttons > *.swiper-next:hover {
  transform: rotate(180deg) scale(1.2);
}
.Appartment-swiper .swiper-buttons > *.swiper-button-disabled {
  opacity: 0;
}
.Appartment-swiper .swiper-buttons > * .arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media screen and (min-width: 768px) {
  .Appartment-swiper .swiper-buttons > * .arrow {
    transform: translate(-50%, -50%);
  }
}
.Appartment-swiper .swiper-buttons > * .arrow svg {
  display: block;
}
.Appartment-swiper .swiper-buttons > * .arrow svg > * {
  fill: transparent;
  stroke: #231f20;
  stroke-width: 1px;
}
.Appartment-swiper .swiper-slide {
  position: absolute;
  transform: translateX(-100%);
  transition: transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  overflow: hidden;
}
.Appartment-swiper .swiper-slide img {
  opacity: 0;
  transform: translateX(50%);
  transition: transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Appartment-swiper .swiper-slide.swiper-slide-active {
  position: relative;
  z-index: 100;
  transform: translateX(0%);
}
.Appartment-swiper .swiper-slide.swiper-slide-active img {
  opacity: 1;
  transform: translateX(0%);
  transition: transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Appartment-swiper .swiper-slide.swiper-slide-active ~ .swiper-slide {
  transform: translateX(100%);
}
.Appartment-swiper .swiper-slide.swiper-slide-active ~ .swiper-slide img {
  transform: translateX(-50%);
}
.Appartment-swiper img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
}
.Appartment-equipementsIcons {
  background: #EFEFEF;
}
.Appartment-equipementsIcons .container {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.Appartment-equipementsIcons .container ul {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  list-style-type: none;
  flex-wrap: wrap;
}
.Appartment-equipementsIcons .container ul li {
  width: 100%;
  max-width: 80px;
  margin: 0 20px 20px;
  text-align: center;
}
.Appartment-equipementsIcons .container ul .icon {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0 auto;
  height: 50px;
}
.Appartment-equipementsIcons .container ul .icon img {
  width: auto;
  max-height: 47px;
  display: block;
  margin: 0 auto;
}
.Appartment-equipementsIcons .container ul .label {
  display: block;
  margin-top: 10px;
  font-weight: bold;
  font-style: italic;
}
.Appartment-content {
  position: relative;
  min-height: 300px;
}
@media screen and (min-width: 992px) {
  .Appartment-content .inner {
    max-width: 50%;
    padding-right: 5%;
  }
}
.Appartment-content .inner p, .Appartment-content .inner .noresult {
  color: #231f20;
  font-size: 1.6rem;
}
.Appartment-content .inner p::first-letter, .Appartment-content .inner .noresult::first-letter {
  font-size: 4.2em;
  line-height: 1;
  float: left;
  margin: 0 20px 10px 0px;
  font-style: italic;
  font-family: "orpheuspro", serif;
}
@media screen and (min-width: 768px) {
  .Appartment-content .inner p::first-letter, .Appartment-content .inner .noresult::first-letter {
    font-size: 6em;
  }
}
.Appartment-video .plyr .plyr__control--overlaid {
  background: #ddb8a2 !important;
  transform-origin: center center;
  box-shadow: none !important;
  width: 12%;
  min-width: 80px;
}
.Appartment-video .plyr .plyr__control--overlaid:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.Appartment-video .plyr .plyr__control--overlaid svg {
  position: absolute;
  top: 50%;
  left: 50%;
  fill: white;
  transform-origin: center;
  margin: 0 auto;
  transform: translate(-50%, -50%);
}
.Appartment-video .plyr input[type=range] {
  color: #ddb8a2;
}
.Appartment-video .plyr .plyr__control.plyr__tab-focus, .Appartment-video .plyr .plyr__control:hover, .Appartment-video .plyr .plyr__control[aria-expanded=true] {
  background: #ddb8a2;
}
.Appartment-video .plyr.plyr--paused .plyr__poster {
  opacity: 0.6;
}
.Appartment-video .plyr.plyr--paused .plyr__controls {
  opacity: 0;
}
.Appartment-location {
  background: #f3f3f3;
}
@media screen and (min-width: 992px) {
  .Appartment-location {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    min-height: 0px;
    top: 0;
    left: 55%;
    right: 0;
  }
}
.Appartment-location #gmap {
  position: relative;
  min-height: 300px;
}
@media screen and (min-width: 992px) {
  .Appartment-location #gmap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
.Appartment-dispo {
  margin-top: 60px;
}
.Appartment-dispo h2 {
  margin-bottom: 20px;
}
.Appartment-dispo .fc.fc-theme-standard td, .Appartment-dispo .fc.fc-theme-standard .fc-scrollgrid, .Appartment-dispo .fc.fc-theme-standard th {
  border-color: rgba(35, 31, 32, 0.1) !important;
}
.Appartment-dispo .fc .fc-button-primary {
  background: #231f20 !important;
  border-color: #312b2c !important;
}
.Appartment-dispo .fc .fc-today-button {
  background: transparent !important;
  color: #231f20 !important;
  font-family: "orpheuspro", serif;
}
.Appartment-dispo .fc .fc-today-button[disabled] {
  opacity: 0.1;
}
.Appartment-dispo .fc .fc-day-today {
  border-color: #ddb8a2 !important;
  background: #ddb8a2 !important;
}
.Appartment-dispo .fc .fc-day.disabled {
  position: relative;
  border-color: rgba(35, 31, 32, 0);
  background: rgba(35, 31, 32, 0.1);
  cursor: not-allowed;
  overflow: hidden;
}
.Appartment-dispo .fc .fc-day.disabled:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 133%;
  height: 1px;
  background: #231f20;
  transform-origin: center;
  transform: translateX(-50%) rotate(-39.5deg);
  opacity: 0.5;
}
.Appartment-dispo .fc .fc-day > * {
  pointer-events: none;
}
.Appartment-equipements {
  background: white;
}
@media screen and (min-width: 992px) {
  .Appartment-equipements h2 {
    margin-bottom: 0px;
  }
}
.Appartment-equipements .list-equipements {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.Appartment-equipements .list-equipements li {
  width: 100%;
  font-size: 2.4rem;
  font-family: "orpheuspro", serif;
  font-style: italic;
  padding-right: 40px;
  margin-bottom: 10px;
  flex-grow: 2;
}
@media screen and (min-width: 768px) {
  .Appartment-equipements .list-equipements li {
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .Appartment-equipements .list-equipements li {
    max-width: 50%;
  }
}
@media screen and (min-width: 1460px) {
  .Appartment-equipements .list-equipements li {
    max-width: 33.3333%;
    font-size: 2.4rem;
  }
}
.Appartment-avis {
  border-top: 1px solid #ebebeb;
  background: white;
}
.Appartment-avis header h2 {
  margin-bottom: 40px;
}
.Appartment-avis header .avis__list li {
  font-size: 1.4rem;
  margin-bottom: 8px;
}
.Appartment-avis .container > .content {
  width: 100%;
}
.Appartment-avis .btn {
  margin-top: 0px;
}
.Appartment-recap {
  background: #fae4d7;
}
@media screen and (min-width: 992px) {
  .Appartment-recap .container {
    display: flex;
  }
}
.Appartment-recap header .visuel__container {
  position: relative;
  overflow: hidden;
  width: 250px;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .Appartment-recap header .visuel__container {
    position: absolute;
    right: 0;
    margin-top: -120px;
  }
}
@media screen and (min-width: 992px) {
  .Appartment-recap header .visuel__container {
    margin-top: -150px;
    position: relative;
  }
}
.Appartment-recap header .visuel__container:after {
  content: "";
  display: block;
  width: 100%;
  padding-top: 110%;
}
.Appartment-recap header .visuel__container img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: auto;
  max-width: none;
}
.Appartment-recap .recap-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: -30px;
}
@media screen and (min-width: 1200px) {
  .Appartment-recap .recap-list {
    margin-bottom: -40px;
  }
}
.Appartment-recap .recap-list > * {
  min-width: 33.333%;
  padding-right: 40px;
}
.Appartment-recap .recap-list .item {
  margin-bottom: 30px;
}
@media screen and (min-width: 1200px) {
  .Appartment-recap .recap-list .item {
    margin-bottom: 40px;
  }
}
.Appartment-recap .recap-list .item-title {
  display: block;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 5px;
}
.Appartment-recap .recap-list .item-value {
  font-family: "orpheuspro", serif;
  font-size: 2.4rem;
  font-style: italic;
}
@media screen and (min-width: 1200px) {
  .Appartment-recap .recap-list .item-value {
    font-size: 3rem;
  }
}
.Appartment-recap .legend, .Appartment-recap .Reservation-dates .info_enfants, .Reservation-dates .Appartment-recap .info_enfants, .Appartment-recap .Reservation-personnes .info_enfants, .Reservation-personnes .Appartment-recap .info_enfants {
  display: block;
  font-size: 1.2rem;
  margin-top: 10px;
  font-style: italic;
}
.Appartment-preview {
  width: 100%;
  margin-bottom: 60px;
}
@media screen and (min-width: 670px) {
  .Appartment-preview {
    max-width: calc(50% - 40px);
    margin-right: 40px;
  }
}
@media screen and (min-width: 992px) {
  .Appartment-preview {
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .Appartment-preview {
    max-width: calc(33.333% - 40px);
  }
}
@media screen and (min-width: 1700px) {
  .Appartment-preview {
    max-width: calc(25% - 40px);
  }
}
.Appartment-preview .visuel-container {
  position: relative;
  background: #f0f0f0;
  overflow: hidden;
  transition: box-shadow 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Appartment-preview .visuel-container:after {
  content: "";
  display: block;
  padding-top: 50%;
}
.Appartment-preview .visuel-container .visuel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
}
.Appartment-preview .visuel-container .visuel img:not(.flag) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Appartment-preview .visuel-container .visuel .notif {
  position: relative;
  z-index: 2;
  margin-bottom: 5px;
}
.Appartment-preview .visuel-container .Selection-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: #ffffff;
  border: 0;
  width: auto;
  height: auto;
  padding: 10px 15px !important;
  border-radius: 3px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
  z-index: 20;
}
.Appartment-preview .visuel-container .Selection-btn svg {
  width: 18px;
  height: 16px;
}
.Appartment-preview .visuel-container .Selection-btn:hover, .Appartment-preview .visuel-container .Selection-btn.selected {
  background: #ddb8a2;
}
.Appartment-preview .visuel-container .Selection-btn:hover svg, .Appartment-preview .visuel-container .Selection-btn.selected svg {
  animation: favAnim 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Appartment-preview .visuel-container .Selection-btn:hover svg > *, .Appartment-preview .visuel-container .Selection-btn.selected svg > * {
  fill: #ffffff;
  stroke: #dab29b;
}
.Appartment-preview .inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 30px;
}
.Appartment-preview .Appartment-title {
  font-size: 2.4rem;
  margin-bottom: 15px;
}
@media screen and (min-width: 1460px) {
  .Appartment-preview .Appartment-title {
    font-size: 3rem;
  }
}
.Appartment-preview .chzn-container {
  margin-bottom: 10px;
}
.Appartment-preview .details {
  margin-right: 20px;
}
.Appartment-preview .actions {
  text-align: right;
}
.Appartment-preview .actions .listeprix {
  margin-bottom: 10px;
}
.Appartment-preview .price {
  font-family: "orpheuspro", serif;
  font-weight: 300 !important;
}
.Appartment-preview .price strong {
  font-size: 2rem !important;
}
.Appartment-preview .price strong small {
  font-size: 1.2rem !important;
}
.Appartment-preview .avis {
  position: relative;
  white-space: nowrap;
}
.Appartment-preview .avis:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 1px;
  background: #231f20;
}
.Appartment-preview .btn {
  padding: 10px 30px;
  margin-top: 20px;
}
.Appartment-preview:hover .visuel-container {
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.2);
}
.Appartment-preview:hover .visuel-container .visuel > img:not(.notif) {
  transform: scale(1.05);
}
.Appartment-categorie {
  position: relative;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  color: #ffffff;
  transition: transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media screen and (min-width: 670px) {
  .Appartment-categorie {
    max-width: calc(50% - 20px);
    margin-right: 20px;
    min-height: 350px;
  }
}
@media screen and (min-width: 1200px) {
  .Appartment-categorie {
    max-width: calc(33.33333% - 20px);
  }
}
.Appartment-categorie:hover {
  transform: scale(0.96);
}
.Appartment-categorie:hover .Appartment-cat {
  transform: translateY(10px);
  opacity: 0;
}
.Appartment-categorie:hover .inner {
  transform: translateY(-10px);
}
.Appartment-categorie:hover .visuel-container img {
  transform: scale(1.1);
  transition-duration: 600ms;
}
.Appartment-categorie.\--7 {
  border: 6px solid #c09a54;
}
.Appartment-categorie.\--7 .visuel-container:after {
  opacity: 0.8;
}
.Appartment-categorie.\--7 .btn {
  background: #c09a54 !important;
}
.Appartment-categorie .inner {
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 40px 20%;
  transition: transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Appartment-categorie .visuel-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
  background: #4b362a;
}
.Appartment-categorie .visuel-container:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #231f20;
  opacity: 0.5;
}
.Appartment-categorie .visuel-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Appartment-categorie .Appartment-title {
  width: 100%;
  font-size: 3.2rem;
}
@media screen and (min-width: 768px) {
  .Appartment-categorie .Appartment-title {
    font-size: 4rem;
  }
}
.Appartment-categorie .btn {
  margin-top: 15px;
}
.Appartment .prix_appt .price__line {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .Appartment .prix_appt .price__line {
    justify-content: flex-end;
  }
}
.Appartment .prix_appt .price__line i {
  font-size: 0.8em;
  display: block;
  letter-spacing: 0;
}
.Appartment .prix_appt .price__line i:empty {
  display: none;
}
.Appartment .prix_appt .price {
  font-size: 3rem;
  font-family: "orpheuspro", serif;
}
.Appartment .prix_appt .price sup {
  position: relative;
  top: 4px;
  font-size: 1.6rem;
  line-height: 1;
  vertical-align: top;
}
.Appartment .prix_appt .price.price--old {
  opacity: 0.3;
  position: relative;
  margin-right: 15px;
}
.Appartment .prix_appt .price.price--old:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 50%;
  left: -2px;
  right: -2px;
  height: 2px;
  background: #231f20;
}
.Appartment .prix_appt .price--taxe {
  text-transform: uppercase;
  min-width: 100px;
  max-width: 100px;
  letter-spacing: 0.1em;
  font-weight: 400;
}
.Appartment .price {
  font-size: 1.2rem;
}
.Appartment .price strong {
  font-family: "orpheuspro", serif;
  font-size: 3.2rem;
  font-weight: 300 !important;
  margin-right: 10px;
}
@media screen and (min-width: 768px) {
  .Appartment .price strong {
    font-size: 2.4rem;
  }
}
@media screen and (min-width: 992px) {
  .Appartment .price strong {
    font-size: 3.2rem;
  }
}
@media screen and (min-width: 1200px) {
  .Appartment .price strong {
    font-size: 4rem;
  }
}
@media screen and (min-width: 992px) {
  .Appartment .price strong small {
    font-size: 1.6rem;
  }
}
.Appartment .rating {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.Appartment .rating .stars {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 10px;
}
.Appartment .rating .stars svg > * {
  fill: #d8d8d8;
}
.Appartment .rating .stars svg.filled > * {
  fill: #3e3739;
}
.Appartment .share {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  margin-right: auto;
  margin-top: 25px;
}
.Appartment .share span {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-right: 12px;
  font-size: 0.9rem;
}
.Appartment .share a {
  margin-right: 10px;
}
.Appartment .share svg {
  display: block;
}
.Appartment .notif {
  display: inline-block;
  background: #231f20;
  padding: 6px 12px;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  color: #ffffff;
  z-index: 2;
  margin-bottom: 10px;
  margin-right: 5px;
}
.Appartment .notif.notif--promo {
  background: #ddb8a2;
}
.Appartment .notif.notif--bien-exception {
  background: #c09a54;
}
.Appartment .Selection-btn {
  position: relative;
  display: inline-block;
  border: 1px solid #231f20;
  border-radius: 3px;
  padding: 14px 18px 13px;
  z-index: 20;
  transition: background 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Appartment .Selection-btn svg {
  display: block;
  overflow: visible;
  width: 24px;
  height: 20px;
  margin: 0 auto;
}
.Appartment .Selection-btn svg > * {
  fill: transparent;
  stroke-width: 1px;
  stroke: #231f20;
  transition: all 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Appartment .Selection-btn:hover {
  background: #ddb8a2;
  border-color: #dab29b;
}
.Appartment .Selection-btn:hover svg {
  animation: favAnim 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Appartment .Selection-btn:hover svg > * {
  fill: #ffffff;
  stroke: #dab29b;
}
.Appartment .block__double > .container, .Appartment.block__double > .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (min-width: 992px) {
  .Appartment .block__double > .container, .Appartment.block__double > .container {
    flex-wrap: nowrap;
  }
}
.Appartment .block__double header, .Appartment.block__double header {
  min-width: 300px;
  margin-right: 10%;
}

@keyframes favAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.Services-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
  align-items: stretch;
  margin-bottom: -60px;
}
@media screen and (min-width: 768px) {
  .Services-list {
    margin-right: -40px;
  }
}
@media screen and (min-width: 992px) {
  .Services-list {
    margin-right: -80px;
    margin-bottom: -80px;
  }
}
@media screen and (min-width: 1200px) {
  .Services-list {
    margin-right: -100px;
  }
}

.Service-item {
  width: 100%;
  margin-bottom: 60px;
  flex-grow: 2;
}
@media screen and (min-width: 768px) {
  .Service-item {
    max-width: calc(50% - 40px);
    margin-right: 40px;
  }
}
@media screen and (min-width: 992px) {
  .Service-item {
    max-width: calc(50% - 80px);
    margin-right: 80px;
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .Service-item {
    max-width: calc(33.333% - 60px);
    margin-right: 60px;
  }
}
@media screen and (min-width: 1460px) {
  .Service-item {
    max-width: calc(33.333% - 100px);
    margin-right: 100px;
  }
}
.Service-item .visuel-container {
  position: relative;
  background: #ebebeb;
  overflow: hidden;
}
.Service-item .visuel-container:before {
  content: "";
  display: block;
  padding-top: 50%;
}
.Service-item .visuel-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Service-item .inner {
  padding-top: 30px;
}
.Service-item .inner .headline {
  margin-bottom: 20px;
}
@media screen and (min-width: 670px) {
  .Service-item .inner .headline {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}
.Service-item .inner h3, .Service-item .inner .Page-account .Login form fieldset legend, .Service-item .inner .Page-creation .Login form fieldset legend, .Page-account .Login form fieldset .Service-item .inner legend, .Page-creation .Login form fieldset .Service-item .inner legend, .Service-item .inner .Page-contact form[name=form_contact] .row .col legend, .Page-contact form[name=form_contact] .row .col .Service-item .inner legend {
  margin-bottom: 0;
  font-size: 2.4rem;
}
@media screen and (min-width: 1460px) {
  .Service-item .inner h3, .Service-item .inner .Page-account .Login form fieldset legend, .Service-item .inner .Page-creation .Login form fieldset legend, .Page-account .Login form fieldset .Service-item .inner legend, .Page-creation .Login form fieldset .Service-item .inner legend, .Service-item .inner .Page-contact form[name=form_contact] .row .col legend, .Page-contact form[name=form_contact] .row .col .Service-item .inner legend {
    font-size: 3rem;
  }
}
.Service-item .inner .price {
  display: inline-block;
  font-family: "orpheuspro", serif;
  font-size: 2.4rem;
  white-space: nowrap;
}
@media screen and (min-width: 670px) {
  .Service-item .inner .price {
    margin-left: 30px;
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 992px) {
  .Service-item .inner .price {
    margin-top: 5px;
  }
}
@media screen and (min-width: 1460px) {
  .Service-item .inner .price {
    font-size: 2rem;
    margin-left: 40px;
  }
}
.Service-item .inner p, .Service-item .inner .noresult {
  width: 100%;
  max-width: 500px;
}
.Service-title {
  font-family: "macho", sans-serif;
  font-weight: 300;
  font-size: 2.6rem;
}
@media screen and (min-width: 992px) {
  .Service-title {
    min-height: 56px;
  }
}

.Avantages-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
  align-items: stretch;
  margin-bottom: -40px;
}
@media screen and (min-width: 768px) {
  .Avantages-list {
    margin-right: -40px;
    margin-bottom: -60px;
  }
}
@media screen and (min-width: 992px) {
  .Avantages-list {
    margin-right: -80px;
    margin-bottom: -80px;
  }
}
@media screen and (min-width: 1200px) {
  .Avantages-list {
    margin-right: -100px;
  }
}

.Avantage-item {
  width: 100%;
  margin-bottom: 40px;
  flex-grow: 2;
}
@media screen and (min-width: 768px) {
  .Avantage-item {
    max-width: calc(50% - 40px);
    margin-bottom: 60px;
    margin-right: 40px;
  }
}
@media screen and (min-width: 992px) {
  .Avantage-item {
    max-width: calc(50% - 80px);
    margin-right: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .Avantage-item {
    max-width: calc(33.333% - 100px);
    margin-right: 100px;
  }
}

.mfp-bg {
  opacity: 0;
  background: #231f20;
  transition: opacity 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.mfp-bg.mfp-ready {
  opacity: 0.8;
}
.mfp-bg.mfp-removing {
  opacity: 0;
  transition-delay: 200ms;
}

@media screen and (min-width: 768px) {
  .mfp-container {
    padding: 0 40px;
  }
}

.mfp-content {
  max-width: 800px;
  margin: 5px auto;
  background: #f8f8f8;
  transform: translateY(200px);
  opacity: 0;
  transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 200ms linear;
}
@media screen and (min-width: 768px) {
  .mfp-content {
    margin: 120px auto;
  }
}
.mfp-ready .mfp-content {
  transform: translateY(0px);
  opacity: 1;
}
.mfp-removing .mfp-content {
  transform: translateY(200px);
  opacity: 0;
  transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 200ms linear;
}
@media screen and (min-width: 768px) {
  .mfp-content .ajax-container .Page-header .container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.mfp-content .ajax-container .Page-header .container h1 {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .mfp-content .ajax-container .Page-header .container h1 {
    font-size: 6rem;
  }
}
.mfp-content .ajax-container > section > .container {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .mfp-content .ajax-container > section > .container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.mfp-content .content {
  padding: 60px;
}

.mfp-close {
  position: absolute;
  z-index: 100;
  top: 10px !important;
  right: 0px !important;
}
@media screen and (min-width: 768px) {
  .mfp-close {
    top: 20px !important;
    right: 20px !important;
  }
}
.mfp-close svg {
  pointer-events: none;
}

#sb-container {
  z-index: 1000 !important;
}

.cgv-inner {
  padding: 40px;
}
.cgv-inner H1 {
  font-size: 3.2rem;
  margin-bottom: 40px;
}
.cgv-inner h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: 20px;
}

.avis {
  transition: transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.avis.hidden {
  opacity: 0;
  display: block;
  height: 0px;
  visibility: hidden;
  transform: translateY(40px);
}
.avis__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.avis__container > * {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .avis__container > * {
    max-width: calc(50% - 40px);
  }
}
.avis__headline {
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 1.1rem;
  letter-spacing: 0.1em;
}
.avis__headline > * {
  display: block;
}
.avis__name {
  font-weight: 700;
}
.avis__text {
  margin-bottom: 40px;
}
.avis__list {
  width: 100%;
  list-style-type: none;
  margin: 0;
  margin-bottom: 80px;
}
.avis__list li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: left;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 5px;
  font-size: 1.1rem;
}
.avis__list li > label {
  margin-bottom: 0px;
}
.avis__note {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.avis__note .custom__rating {
  position: relative;
  margin-left: 2px;
  cursor: pointer;
}
.avis__note .custom__rating input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.avis__note .custom__rating.filled svg > * {
  fill: #3e3739;
}
.avis__note .custom__rating svg {
  pointer-events: none;
}
.avis__note .custom__rating svg > * {
  fill: #d8d8d8;
}
.avis__locataire {
  padding: 40px;
}
.avis__locataire h1 {
  font-size: 4rem;
  margin-bottom: 0;
}
.avis__locataire .formulaire_avis {
  margin-top: 40px;
}
.avis__locataire h2 {
  font-size: 2.4rem;
  margin-bottom: 20px;
}
.avis__locataire .avis__list {
  margin-bottom: 40px;
}
.avis__locataire .avis__list li {
  margin-bottom: 10px;
}
.avis__locataire label {
  margin-bottom: 0px;
}
.avis__locataire .checkbox {
  text-transform: none;
  letter-spacing: 0;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.avis__locataire .checkbox input {
  margin-right: 5px;
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }
  #barba-wrapper .barba-container {
    background: transparent;
  }
  .Topbar, .Footer, .share, .btn, .Selection-btn, .Appartment-swiper, .Appartment-recap .visuel__container {
    display: none !important;
  }
  .Header {
    margin-top: 40px;
    margin-bottom: -40px;
  }
  .Page-account .Page-header .Account-nav,
  .Page-account section > .container > .headline, .Page-creation .Page-header .Account-nav,
  .Page-creation section > .container > .headline {
    display: none !important;
  }
  .Page-account .Appartment-preview, .Page-creation .Appartment-preview {
    max-width: calc(50% - 40px);
    margin-right: 40px;
  }
  .Page-account .Appartment-preview .Appartment-title, .Page-creation .Appartment-preview .Appartment-title {
    font-size: 24px;
  }
  .prix_appt > * {
    display: block !important;
    text-align: left !important;
  }
  .list-equipements {
    display: flex() !important;
  }
  .list-equipements > li {
    max-width: 33.333% !important;
    font-size: 1.8rem !important;
  }
  .legend, .Reservation-dates .info_enfants, .Reservation-personnes .info_enfants {
    text-align: left !important;
  }
}
.Page-title {
  position: absolute;
  bottom: 100%;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 992px) {
  .Page-title {
    top: 0;
    left: 0;
    bottom: initial;
    transform-origin: bottom left;
    transform: rotate(-90deg) translateX(-100%);
    margin-left: -30px;
    margin-top: -10px;
  }
}
@media screen and (min-width: 1200px) {
  .Page-title {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 1700px) {
  .Page-title {
    margin-left: -40px;
  }
}
.Page-header {
  background: #ffffff;
}
.Page-header .visuel-container {
  position: relative;
  overflow: hidden;
  height: 200px;
  background: #f8f8f8;
}
@media screen and (min-width: 992px) {
  .Page-header .visuel-container {
    height: 35vh;
  }
}
@media screen and (min-width: 1200px) {
  .Page-header .visuel-container {
    height: 42vh;
  }
}
.Page-header .visuel-container .visuel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.Page-header .visuel-container .visuel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Page-header .visuel-container + .container {
  margin-top: -22px;
  padding-top: 0px;
}
@media screen and (min-width: 670px) {
  .Page-header .visuel-container + .container {
    margin-top: -20px;
  }
}
@media screen and (min-width: 1200px) {
  .Page-header .visuel-container + .container {
    margin-top: -40px;
  }
}
@media screen and (min-width: 1460px) {
  .Page-header .visuel-container + .container {
    margin-top: -50px;
  }
}
.Page-header .container {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .Page-header .container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .Page-header .container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media screen and (min-width: 1460px) {
  .Page-header .container {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.Page-header .wrapper {
  position: relative;
}
.Page-header h1 {
  font-size: 3.6rem;
  line-height: 0.9;
  margin-bottom: 40px;
}
@media screen and (min-width: 992px) {
  .Page-header h1 {
    font-size: 4.8rem;
    margin-bottom: 0px;
  }
}
@media screen and (min-width: 1200px) {
  .Page-header h1 {
    font-size: 4.8rem;
  }
}
@media screen and (min-width: 1460px) {
  .Page-header h1 {
    font-size: 9rem;
  }
}
@media screen and (min-width: 992px) {
  .Page-header .intro {
    margin-left: 60%;
    max-width: 350px;
    width: 40%;
    margin-top: -40px;
  }
}
.Page-header p, .Page-header .noresult {
  font-size: 1.5rem;
}
.Page-header p + *, .Page-header .noresult + * {
  margin-top: 20px;
}
.Page-header a {
  margin-top: 40px;
}
.Page-footer {
  margin-top: 80px;
  padding-bottom: 120px;
}
.Page-paragraphes h1 {
  font-size: 3.6rem;
  margin-bottom: 40px;
}
@media screen and (min-width: 670px) {
  .Page-paragraphes h1 {
    font-size: 4.8rem;
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 1200px) {
  .Page-paragraphes h1 {
    font-size: 6rem;
    margin-bottom: 80px;
  }
}
.Page-paragraphes .Page-content .container {
  padding-top: 60px;
}
@media screen and (min-width: 768px) {
  .Page-paragraphes .Page-content .container {
    padding-top: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .Page-paragraphes .Page-content .container {
    padding-top: 120px;
  }
}
.Page-paragraphes .Page-content article + article {
  margin-top: 60px;
}

.Page-home {
  padding-bottom: 0px !important;
}
.Page-home .Hero .visuel-container {
  will-change: transform;
}
.Page-home section {
  text-align: center;
}
.Page-home section h2 i {
  display: block;
}
.Page-home .Presentation {
  text-align: left;
}
.Page-home .Presentation > .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.Page-home .Presentation > .container > * {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .Page-home .Presentation > .container > * {
    max-width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .Page-home .Presentation p, .Page-home .Presentation .noresult {
    max-width: 380px;
  }
}
.Page-home .Presentation .visuel-container {
  margin-top: 40px;
  padding-left: 0px;
}
@media screen and (min-width: 768px) {
  .Page-home .Presentation .visuel-container {
    margin-top: 0px;
    padding-left: 40px;
  }
}
.Page-home .Presentation img {
  width: 100%;
  margin: 0 auto;
}
.Page-home .Difference {
  background: #ffffff;
}
.Page-home .Difference-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  list-style-type: none;
}
.Page-home .Difference-item {
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
}
@media screen and (min-width: 1200px) {
  .Page-home .Difference-item {
    max-width: calc(25% - 40px);
  }
}
.Page-home .Difference-item p, .Page-home .Difference-item .noresult {
  max-width: 250px;
  margin: 0 auto;
}
.Page-home .Difference-item .visuel-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 150px;
  position: relative;
  max-width: 160px;
  margin: 0 auto 20px;
}
.Page-home .Difference-item .visuel-container img {
  position: relative;
  z-index: 2;
  margin-bottom: 15px;
}
.Page-home .Difference-item .visuel-container:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 100%;
  border-radius: 300px;
  background: #f5eee9;
  transform-origin: bottom center;
  transform: scaleX(1) scaleY(0.2);
}
.Page-home .Services {
  background: #fae4d7;
}
.Page-home .Services-list {
  margin-bottom: -50px;
}
@media screen and (min-width: 670px) {
  .Page-home .Services-list {
    margin-right: -40px;
  }
}
.Page-home .Services .Service-item {
  margin-bottom: 60px;
}
@media screen and (min-width: 670px) {
  .Page-home .Services .Service-item {
    max-width: calc(50% - 40px);
    margin-right: 40px;
  }
}
@media screen and (min-width: 992px) {
  .Page-home .Services .Service-item {
    max-width: calc(33.333% - 40px);
  }
}
@media screen and (min-width: 1200px) {
  .Page-home .Services .Service-item {
    max-width: calc(20% - 40px);
  }
}
.Page-home .Services .Service-item .visuel-container {
  display: block;
  margin: 0 auto 40px;
  max-width: 170px;
  border-radius: 170px;
}
.Page-home .Services .Service-item .visuel-container:before {
  padding-top: 100%;
}
.Page-home .Services .Service-item .visuel-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Page-home .Appartments-list {
  justify-content: center;
  margin-bottom: -20px;
  margin-left: -10px;
  margin-right: -10px;
}
@media screen and (min-width: 670px) {
  .Page-home .Appartments-list {
    margin-left: -20px;
    margin-right: 0px;
  }
}

.Page-appartements .Page-header {
  background: 0;
}
.Page-appartements .Page-header h1 {
  margin-bottom: 10px;
}
@media screen and (min-width: 1460px) {
  .Page-appartements .Page-header h1 {
    font-size: 6.5rem;
  }
}

.Page-services h2.lined i {
  display: block;
}
.Page-services h2.lined:after {
  background: #eaac2f;
}
.Page-services .Page-header {
  background: #ffffff;
}
.Page-services .Page-content .Services:first-child {
  background: #ffffff;
  padding-bottom: 130px;
}
.Page-services .Page-content .Services:first-child .container:before {
  content: "";
  display: block;
  height: 1px;
  background: #dfdfdf;
  margin-bottom: 80px;
  margin-left: 33%;
}
@media screen and (min-width: 1200px) {
  .Page-services .Page-content .Services:first-child .Service-item {
    max-width: calc(50% - 100px);
    margin-right: 100px;
  }
}
.Page-services .Page-content .Services:first-child + .Services {
  margin-top: -18px;
}
@media screen and (min-width: 670px) {
  .Page-services .Page-content .Services:first-child + .Services {
    margin-top: -25px;
  }
}
@media screen and (min-width: 992px) {
  .Page-services .Page-content .Services:first-child + .Services {
    margin-top: -30px;
  }
}

.Page-proprios {
  padding-bottom: 0px !important;
}
.Page-proprios .Avantages {
  margin-top: -18px;
}
@media screen and (min-width: 768px) {
  .Page-proprios .Avantages {
    margin-top: -30px;
  }
}
.Page-proprios .Avantages > .container {
  padding-top: 0px !important;
}
@media screen and (min-width: 768px) {
  .Page-proprios .Avantages h2 {
    margin-bottom: 80px;
  }
}
.Page-proprios .Investissement {
  position: relative;
}
@media screen and (min-width: 768px) {
  .Page-proprios .Investissement {
    margin-top: 5vw;
  }
}
.Page-proprios .Investissement:before {
  content: "";
  display: block;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
}
@media screen and (min-width: 768px) {
  .Page-proprios .Investissement:before {
    top: 10vw;
  }
}
.Page-proprios .Investissement .container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.Page-proprios .Investissement .container > * {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .Page-proprios .Investissement .container .visuel-container {
    order: 1;
    max-width: 80%;
  }
}
@media screen and (min-width: 1200px) {
  .Page-proprios .Investissement .container .visuel-container {
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .Page-proprios .Investissement .container .visuel-container .visuel {
    margin-left: -5%;
  }
}
@media screen and (min-width: 1200px) {
  .Page-proprios .Investissement .container .visuel-container .visuel {
    margin-left: -10%;
  }
}
@media screen and (min-width: 768px) {
  .Page-proprios .Investissement .container header {
    order: 2;
    margin-top: 60px;
  }
}
@media screen and (min-width: 992px) {
  .Page-proprios .Investissement .container header {
    margin-top: 80px;
    margin-left: 15%;
  }
}
@media screen and (min-width: 1200px) {
  .Page-proprios .Investissement .container header {
    max-width: 50%;
    margin-left: 0;
    padding-left: 10%;
    margin-top: 0px;
  }
}
@media screen and (min-width: 768px) {
  .Page-proprios .Investissement .container header h2 {
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 1200px) {
  .Page-proprios .Investissement .container header h2 {
    margin-bottom: 0px;
  }
}
.Page-proprios .Investissement .container .content {
  order: 3;
  max-width: 600px;
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .Page-proprios .Investissement .container .content {
    columns: 2;
    column-gap: 40px;
    margin-top: 0;
  }
}
@media screen and (min-width: 992px) {
  .Page-proprios .Investissement .container .content {
    margin-left: 15%;
    column-gap: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .Page-proprios .Investissement .container .content {
    margin-left: 30%;
    margin-top: 10vh;
  }
}
.Page-proprios .Investissement .container .content * + * {
  margin-top: 20px;
}
.Page-proprios .Retour {
  background: #ffffff;
}
.Page-proprios .Retour .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (min-width: 1200px) {
  .Page-proprios .Retour .container {
    padding-bottom: 0px !important;
  }
}
.Page-proprios .Retour .container header {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .Page-proprios .Retour .container header {
    order: 1;
  }
}
@media screen and (min-width: 768px) {
  .Page-proprios .Retour .container header h2 {
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 992px) {
  .Page-proprios .Retour .container header h2 {
    margin-bottom: 10vh;
  }
}
.Page-proprios .Retour .container .content {
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .Page-proprios .Retour .container .content {
    order: 2;
    max-width: 50%;
    margin-top: 0px;
  }
}
@media screen and (min-width: 992px) {
  .Page-proprios .Retour .container .content {
    max-width: 52%;
  }
}
@media screen and (min-width: 1200px) {
  .Page-proprios .Retour .container .content {
    columns: 2;
    column-gap: 60px;
  }
}
.Page-proprios .Retour .container .content * + * {
  margin-top: 20px;
}
.Page-proprios .Retour .container .content .btn {
  margin-top: 40px;
}
@media screen and (min-width: 1200px) {
  .Page-proprios .Retour .container .content .btn {
    margin-top: 80px;
  }
}
@media screen and (min-width: 768px) {
  .Page-proprios .Retour .container .visuel-container {
    max-width: 40%;
    order: 3;
  }
}
@media screen and (min-width: 992px) {
  .Page-proprios .Retour .container .visuel-container {
    margin-top: -10vw;
  }
}
.Page-proprios .Renove {
  background: #fae4d7;
}
@media screen and (min-width: 1200px) {
  .Page-proprios .Renove {
    margin-top: -60px;
  }
}
.Page-proprios .Renove .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.Page-proprios .Renove .container > * {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .Page-proprios .Renove .container > * {
    max-width: 50%;
  }
}
.Page-proprios .Renove .inner {
  padding-right: 40px;
}
@media screen and (min-width: 992px) {
  .Page-proprios .Renove .inner {
    order: 2;
    padding-left: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .Page-proprios .Renove .inner {
    padding-left: 80px;
  }
}
.Page-proprios .Renove .inner p, .Page-proprios .Renove .inner .noresult {
  max-width: 350px;
}
.Page-proprios .Renove .inner p + *, .Page-proprios .Renove .inner .noresult + * {
  margin-top: 20px;
}
.Page-proprios .Renove .visuel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .Page-proprios .Renove .visuel-container {
    max-width: 40%;
    margin-top: 0;
  }
}
@media screen and (min-width: 992px) {
  .Page-proprios .Renove .visuel-container {
    order: 1;
    max-width: 45%;
  }
}
.Page-proprios .Renove .visuel-container .visuel {
  max-width: 400px;
}

.Page-proposer {
  padding-bottom: 0px !important;
}
.Page-proposer .Page-header {
  background: 0;
}
.mfp-content .Page-proposer .Page-header {
  text-align: center;
}
.Page-proposer .formulaire {
  background: #ffffff;
}

.Page-about {
  padding-bottom: 0 !important;
}
.Page-about .Page-header {
  background: #838770;
  color: #ffffff;
}
.Page-about .Page-header .container {
  padding-top: 360px;
}
@media screen and (min-width: 1200px) {
  .Page-about .Page-header .container {
    padding-top: 360px;
  }
}
.Page-about .Page-header .visuel-container {
  display: none;
  background: #231f20;
}
@media screen and (min-width: 768px) {
  .Page-about .Page-header .visuel-container {
    display: block;
    position: absolute;
    top: -150px;
    right: 0px;
    width: 45%;
    height: 600px;
    max-width: 650px;
  }
}
@media screen and (min-width: 992px) {
  .Page-about .Page-header .visuel-container {
    right: -30px;
    width: 35%;
  }
}
@media screen and (min-width: 1200px) {
  .Page-about .Page-header .visuel-container {
    width: 45%;
    height: 800px;
    right: 0;
  }
}
.Page-about .Page-header h1 {
  position: relative;
  z-index: 20;
}
.Page-about .Page-header .intro {
  width: auto;
  max-width: 500px;
  margin-left: 6%;
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .Page-about .Page-header .intro {
    margin-right: 60%;
  }
}
@media screen and (min-width: 1200px) {
  .Page-about .Page-header .intro {
    margin-top: 120px;
  }
}
.Page-about .Page-header .intro p, .Page-about .Page-header .intro .noresult {
  color: #ffffff;
}
.Page-about .Simplicite {
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 768px) {
  .Page-about .Simplicite > .container {
    padding-bottom: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.Page-about .Simplicite > .container header {
  width: 100%;
  order: 1;
}
.Page-about .Simplicite > .container header h2 {
  max-width: 480px;
}
@media screen and (min-width: 768px) {
  .Page-about .Simplicite > .container header h2 {
    width: 65%;
  }
}
.Page-about .Simplicite > .container .content {
  order: 2;
}
@media screen and (min-width: 768px) {
  .Page-about .Simplicite > .container .content {
    width: 60%;
    padding-right: 80px;
  }
}
.Page-about .Simplicite > .container .content p, .Page-about .Simplicite > .container .content .noresult {
  max-width: 500px;
}
.Page-about .Simplicite > .container .visuel-container {
  width: 100%;
  overflow: hidden;
  max-width: 180px;
  position: relative;
  order: 3;
  display: none;
}
@media screen and (min-width: 768px) {
  .Page-about .Simplicite > .container .visuel-container {
    width: 40%;
    max-width: 330px;
    margin: 0;
    display: block;
  }
}
.Page-about .Simplicite > .container .visuel-container:before {
  content: "";
  display: block;
  padding-top: 200%;
}
.Page-about .Simplicite > .container .visuel-container .visuel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.Page-about .Simplicite > .container .visuel-container .visuel img {
  position: absolute;
  left: 50%;
  height: 100%;
  width: auto;
  max-width: none;
  transform: translateX(-50%);
}

.Team {
  background: #e8ebda;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .Team {
    margin-top: -40px;
  }
}
@media screen and (min-width: 992px) {
  .Team {
    margin-top: -140px;
  }
}
@media screen and (min-width: 992px) {
  .Team header {
    position: absolute;
    top: 0;
    transform: translateY(-38%);
  }
}
.Team .content {
  max-width: 500px;
}
@media screen and (min-width: 992px) {
  .Team .content {
    width: 50%;
  }
}
.Team > .container {
  position: relative;
  z-index: 1;
}
.Team-list {
  margin-top: 60px;
  margin-bottom: -60px;
}
@media screen and (min-width: 768px) {
  .Team-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .Team-list {
    margin-left: 30%;
  }
}
.Team-item {
  width: 100%;
  max-width: 225px;
  margin: 0 auto 60px;
}
@media screen and (min-width: 768px) {
  .Team-item {
    width: calc(33.333% - 40px);
    margin-left: 0;
    margin-right: 0;
  }
}
.Team-item .inner {
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .Team-item .inner {
    padding-top: 30px;
  }
}
.Team-item .name {
  display: block;
  font-family: "orpheuspro", serif;
  font-size: 2.4rem;
}
@media screen and (min-width: 1200px) {
  .Team-item .name {
    font-size: 3rem;
  }
}
.Team-item .job {
  display: block;
  font-size: 1.2rem;
  text-transform: uppercase;
}
@media screen and (min-width: 1200px) {
  .Team-item .job {
    font-size: 1.3rem;
  }
}
.Team-item p, .Team-item .noresult {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .Team-item p, .Team-item .noresult {
    margin-top: 40px;
  }
}

.Page-howitworks {
  padding-bottom: 0 !important;
  background: #ffffff !important;
  border-bottom: 1px solid #e9e9e9;
}
.Page-howitworks .Page-header {
  background: #f0f0f0;
}
.Page-howitworks .Page-header .container {
  padding-top: 200px;
}
@media screen and (min-width: 768px) {
  .Page-howitworks .Page-header .container {
    padding-top: 240px;
  }
}
@media screen and (min-width: 1200px) {
  .Page-howitworks .Page-header .container {
    padding-top: 360px;
  }
}
.Page-howitworks .Page-header .visuel-container {
  display: none;
}
@media screen and (min-width: 768px) {
  .Page-howitworks .Page-header .visuel-container {
    display: block;
    position: absolute;
    top: -150px;
    right: 0px;
    width: 45%;
    height: 400px;
    max-width: 650px;
  }
}
@media screen and (min-width: 992px) {
  .Page-howitworks .Page-header .visuel-container {
    right: -30px;
    height: 600px;
    width: 35%;
  }
}
@media screen and (min-width: 1200px) {
  .Page-howitworks .Page-header .visuel-container {
    width: 45%;
    height: 800px;
    right: 0;
  }
}
.Page-howitworks .Page-header h1 {
  position: relative;
  z-index: 20;
  margin-bottom: 0 !important;
}
.Page-howitworks .Page-header .intro {
  width: auto;
  max-width: 500px;
  margin-left: 0%;
  margin-top: 40px;
}
@media screen and (min-width: 768px) {
  .Page-howitworks .Page-header .intro {
    margin-right: 60%;
  }
}
@media screen and (min-width: 1200px) {
  .Page-howitworks .Page-header .intro {
    margin-top: 120px;
  }
}
.Page-howitworks .Page-header .intro p, .Page-howitworks .Page-header .intro .noresult {
  color: #ffffff;
}

@media screen and (min-width: 768px) {
  .Steps {
    margin-top: -30px;
  }
}
@media screen and (min-width: 1200px) {
  .Steps > .container {
    padding-top: 0px !important;
  }
}
@media screen and (min-width: 992px) {
  .Steps-list {
    max-width: 55%;
  }
}
@media screen and (min-width: 1200px) {
  .Steps-list {
    max-width: 45%;
  }
}
.Steps-item {
  position: relative;
  margin-bottom: 60px;
  padding-left: 60px;
  padding-top: 5px;
}
@media screen and (min-width: 768px) {
  .Steps-item {
    padding-left: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .Steps-item {
    padding-left: 100px;
    padding-top: 8px;
  }
}
.Steps-item .num {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 40px;
  line-height: 40px;
  border-radius: 40px;
  text-align: center;
  border: 1px solid #231f20;
  font-size: 1.8rem;
  font-family: "orpheuspro", serif;
  font-style: italic;
}
@media screen and (min-width: 1200px) {
  .Steps-item .num {
    width: 60px;
    line-height: 60px;
    border-radius: 60px;
    font-size: 2.4rem;
  }
}
.Steps-item .title {
  display: block;
  font-family: "orpheuspro", serif;
  font-size: 2.4rem;
  margin-bottom: 20px;
  font-style: italic;
}
@media screen and (min-width: 768px) {
  .Steps-item .title {
    font-size: 3rem;
  }
}
@media screen and (min-width: 768px) {
  .Steps .legend, .Steps .Reservation-dates .info_enfants, .Reservation-dates .Steps .info_enfants, .Steps .Reservation-personnes .info_enfants, .Reservation-personnes .Steps .info_enfants {
    margin-left: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .Steps .legend, .Steps .Reservation-dates .info_enfants, .Reservation-dates .Steps .info_enfants, .Steps .Reservation-personnes .info_enfants, .Reservation-personnes .Steps .info_enfants {
    margin-left: 100px;
  }
}
.Steps .legend strong, .Steps .Reservation-dates .info_enfants strong, .Reservation-dates .Steps .info_enfants strong, .Steps .Reservation-personnes .info_enfants strong, .Reservation-personnes .Steps .info_enfants strong {
  font-weight: 500;
  color: #231f20;
}

.Selection {
  background: #000000;
  color: #ffffff;
  margin-top: 40px;
}
@media screen and (min-width: 1460px) {
  .Selection .container {
    padding-top: 15vh !important;
    padding-bottom: 15vh !important;
  }
}
.Selection .content p, .Selection .content .noresult {
  color: #ffffff;
}
.Selection .content p + *, .Selection .content .noresult + * {
  margin-top: 20px;
}
.Selection .content .btn {
  margin-top: 60px;
}
.Selection .visuel-container {
  position: relative;
  margin-bottom: 120px;
  margin-top: -80px;
}
@media screen and (min-width: 992px) {
  .Selection .visuel-container {
    position: absolute;
    top: -40px;
    left: -40px;
    width: 50%;
    margin-top: 0px;
  }
}
.Selection .visuel-container.visuel-01 {
  width: 60%;
  margin-bottom: 40px;
}
.Selection .visuel-container.visuel-02 {
  position: absolute;
  top: 25%;
  left: calc(60% + 20px);
  right: 0;
}
.Selection .visuel-container.visuel-03 {
  width: 50%;
  margin-left: 10%;
}
.Selection .visuel-container.visuel-04 {
  position: absolute;
  right: 6%;
  width: 24%;
  margin-top: -25%;
}
@media screen and (min-width: 992px) {
  .Selection .inner {
    margin-left: calc(50% + 80px);
  }
}

@media screen and (min-width: 992px) {
  .FAQ {
    margin-top: 40px;
  }
}
.FAQ > .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media screen and (min-width: 1200px) {
  .FAQ > .container {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 1460px) {
  .FAQ > .container {
    padding-top: 15vh !important;
    padding-bottom: 15vh !important;
  }
}
.FAQ > .container > * {
  width: 100%;
}
.FAQ header {
  width: 100%;
  padding-right: 80px;
}
@media screen and (min-width: 992px) {
  .FAQ header {
    max-width: 40%;
  }
}
.FAQ header h2 {
  margin-bottom: 40px;
  white-space: nowrap;
}
@media screen and (min-width: 768px) {
  .FAQ header h2 {
    font-size: 4rem;
  }
}
@media screen and (min-width: 992px) {
  .FAQ header h2 {
    margin-bottom: 60px;
  }
}
.FAQ header p, .FAQ header .noresult {
  max-width: 250px;
  margin-bottom: 10px;
  color: #231f20;
}
.FAQ header a {
  display: inline-block;
  font-size: 2.4rem;
  font-family: "orpheuspro", serif;
  margin-bottom: 10px;
}
.FAQ header .legend, .FAQ header .Reservation-dates .info_enfants, .Reservation-dates .FAQ header .info_enfants, .FAQ header .Reservation-personnes .info_enfants, .Reservation-personnes .FAQ header .info_enfants {
  display: block;
  font-size: 1.2rem;
}
.FAQ-list {
  width: 100%;
  margin-top: 40px;
}
@media screen and (min-width: 992px) {
  .FAQ-list {
    max-width: 60%;
    margin-top: 0px;
  }
}
.FAQ-item .question {
  display: block;
  font-size: 2rem;
  font-weight: 300;
  padding: 12px 0px 16px;
}
@media screen and (min-width: 1200px) {
  .FAQ-item .question {
    font-size: 2.4rem;
  }
}
.FAQ-item .question-collapse {
  overflow: hidden;
  height: 0px;
  border-bottom: 1px solid #e9e9e9;
}
.FAQ-item .inner {
  padding: 0px 0px 30px;
}
.FAQ-item.active .question-collapse {
  height: auto;
}

.Page-contact {
  padding-bottom: 0 !important;
}
.Page-contact .Page-header h1 {
  margin-bottom: 20px;
}
@media screen and (min-width: 1200px) {
  .Page-contact .Page-header h1 {
    font-size: 6.5rem;
  }
}
.Page-contact .Page-header p, .Page-contact .Page-header .noresult {
  max-width: 760px;
}
.Page-contact form[name=form_contact] {
  position: relative;
  z-index: 2;
}
.Page-contact form[name=form_contact] .row .col {
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}
@media screen and (min-width: 1200px) {
  .Page-contact form[name=form_contact] .row .col {
    max-width: calc(50% - 40px);
  }
}
.Page-contact form[name=form_contact] .row .col legend {
  display: block;
  margin-bottom: 40px;
}
@media screen and (min-width: 1200px) {
  .Page-contact form[name=form_contact] .row .col textarea {
    margin-top: 30px;
  }
}
@media screen and (max-width: 1199px) {
  .Page-contact form[name=form_contact] .form-actions {
    margin-top: 20px;
  }
}

.Page-account, .Page-creation {
  padding-bottom: 0px !important;
}
.Page-account .Page-header, .Page-creation .Page-header {
  background: 0;
}
.Page-account .Page-header > .container, .Page-creation .Page-header > .container {
  padding-bottom: 0;
}
.Page-account .Page-header h1, .Page-creation .Page-header h1 {
  margin-bottom: 20px;
}
@media screen and (min-width: 1200px) {
  .Page-account .Page-header h1, .Page-creation .Page-header h1 {
    font-size: 6.5rem;
  }
}
.Page-account .Page-header h1 i, .Page-creation .Page-header h1 i {
  color: #ddb8a2;
}
.Page-account .Page-header p, .Page-creation .Page-header p, .Page-account .Page-header .noresult, .Page-creation .Page-header .noresult {
  max-width: 600px;
  font-size: 1.6rem;
  margin-top: 20px;
}
.Page-account .Page-header .Account-nav, .Page-creation .Page-header .Account-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
}
.Page-account .Page-header .Account-nav a, .Page-creation .Page-header .Account-nav a {
  margin: 0px;
  margin-right: 40px;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  position: relative;
  margin-bottom: 10px;
}
.Page-account .Page-header .Account-nav a:after, .Page-creation .Page-header .Account-nav a:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #231f20;
  margin-top: 3px;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Page-account .Page-header .Account-nav a:hover:after, .Page-creation .Page-header .Account-nav a:hover:after, .Page-account .Page-header .Account-nav a.active:after, .Page-creation .Page-header .Account-nav a.active:after {
  transform: scale(1);
}
.Page-account .Login > .container, .Page-creation .Login > .container {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.Page-account .Login form, .Page-creation .Login form {
  width: 100%;
  margin-bottom: 20px;
}
@media screen and (min-width: 1200px) {
  .Page-account .Login form, .Page-creation .Login form {
    max-width: calc(50% - 30px);
    margin-bottom: 0px;
  }
}
.Page-account .Login form[name=form_login_proprietaire] fieldset, .Page-creation .Login form[name=form_login_proprietaire] fieldset {
  background: url("../img/account/proprio_bg.jpg") no-repeat center center #7e9a68;
  background-size: cover;
}
.Page-account .Login form fieldset, .Page-creation .Login form fieldset {
  margin: 0;
  border: 0;
  padding: 30px;
  background: url("../img/account/particulier_bg.jpg") no-repeat center center #4b362a;
  background-size: cover;
  color: #ffffff;
}
@media screen and (min-width: 768px) {
  .Page-account .Login form fieldset, .Page-creation .Login form fieldset {
    padding: 50px;
  }
}
.Page-account .Login form fieldset legend, .Page-creation .Login form fieldset legend {
  display: table;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-bottom: 20px;
}
.Page-account .Login form fieldset legend + *, .Page-creation .Login form fieldset legend + * {
  clear: both;
}
.Page-account .Login form fieldset .form-group, .Page-creation .Login form fieldset .form-group {
  margin-bottom: 0;
}
.Page-account .Login form fieldset .form-actions, .Page-creation .Login form fieldset .form-actions {
  margin-top: 20px;
  text-align: left;
}
@media screen and (min-width: 670px) {
  .Page-account .Login form fieldset .form-actions, .Page-creation .Login form fieldset .form-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}
.Page-account .Login form fieldset .form-actions .btn, .Page-creation .Login form fieldset .form-actions .btn {
  margin-top: 0;
  padding: 8px 40px;
  height: auto;
  min-height: 50px;
  line-height: 1.2;
  font-size: 2rem;
  font-style: normal;
  margin-top: 20px;
}
@media screen and (min-width: 670px) {
  .Page-account .Login form fieldset .form-actions .btn, .Page-creation .Login form fieldset .form-actions .btn {
    margin-top: 0px;
  }
}
.Page-account .Login form fieldset .form-actions .link:after, .Page-creation .Login form fieldset .form-actions .link:after {
  background: #ffffff;
}
.Page-account .Login form fieldset input.form-control, .Page-creation .Login form fieldset input.form-control {
  border: 0;
  margin-bottom: 10px;
  background: #ffffff;
}
.Page-account .Login form > span, .Page-creation .Login form > span {
  display: block;
  color: red;
  margin-top: 10px;
  text-align: right;
}
.Page-account .headline, .Page-creation .headline {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;
}
.Page-account .selection > .container, .Page-creation .selection > .container {
  padding-top: 40px !important;
}
@media screen and (min-width: 1200px) {
  .Page-account .selection .Appartment-preview, .Page-creation .selection .Appartment-preview {
    max-width: calc(33.333% - 40px);
  }
}
.Page-account .state, .Page-creation .state {
  display: inline-block;
  padding: 6px 12px;
  background: #231f20;
  color: #ffffff;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.Page-account .state.is-pending, .Page-creation .state.is-pending {
  background: #231f20;
}
.Page-account .state.is-valide, .Page-creation .state.is-valide {
  background: #7e9a68;
}
.Page-account .state.is-cancelled, .Page-creation .state.is-cancelled {
  background: #f01414;
}
.Page-account .state.is-current, .Page-creation .state.is-current {
  background: #ddb8a2;
}
.Page-account .Reservations > .container, .Page-creation .Reservations > .container {
  padding-top: 40px !important;
}
.Page-account .Reservations .Appartments-list, .Page-creation .Reservations .Appartments-list {
  margin: 0;
}
.Page-account .Reservations .Appartment-preview, .Page-creation .Reservations .Appartment-preview {
  max-width: 100% !important;
  margin: 40px 0px;
}
.Page-account .Reservations .Appartment-preview:last-child, .Page-creation .Reservations .Appartment-preview:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .Page-account .Reservations .Appartment-preview .row, .Page-creation .Reservations .Appartment-preview .row {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 768px) {
  .Page-account .Reservations .Appartment-preview .lien_photo, .Page-creation .Reservations .Appartment-preview .lien_photo {
    min-width: 280px;
  }
}
.Page-account .Reservations .Appartment-preview .lien_photo img, .Page-creation .Reservations .Appartment-preview .lien_photo img {
  display: block;
  max-width: 100%;
  height: auto;
}
.Page-account .Reservations .Appartment-preview .details, .Page-creation .Reservations .Appartment-preview .details {
  margin: 0;
  width: 100%;
  position: relative;
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .Page-account .Reservations .Appartment-preview .details, .Page-creation .Reservations .Appartment-preview .details {
    max-width: calc(100% - 28px);
    padding-left: 40px;
    margin-top: 0px;
  }
}
@media screen and (min-width: 992px) {
  .Page-account .Reservations .Appartment-preview .details, .Page-creation .Reservations .Appartment-preview .details {
    padding-right: 230px;
  }
}
.Page-account .Reservations .Appartment-preview .details .Appartment-title, .Page-creation .Reservations .Appartment-preview .details .Appartment-title {
  margin-bottom: 0;
}
.Page-account .Reservations .Appartment-preview .details .details__reservation, .Page-creation .Reservations .Appartment-preview .details .details__reservation {
  margin-top: 10px;
}
.Page-account .Reservations .Appartment-preview .details .details__reservation span, .Page-creation .Reservations .Appartment-preview .details .details__reservation span {
  display: block;
}
.Page-account .Reservations .Appartment-preview .details strong, .Page-creation .Reservations .Appartment-preview .details strong {
  font-weight: 700;
}
.Page-account .Reservations .Appartment-preview .details strong > span, .Page-creation .Reservations .Appartment-preview .details strong > span {
  display: inline !important;
}
.Page-account .Reservations .Appartment-preview .detail_loc, .Page-creation .Reservations .Appartment-preview .detail_loc {
  background: #ffffff;
  margin: 30px 0 0;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 768px) {
  .Page-account .Reservations .Appartment-preview .detail_loc, .Page-creation .Reservations .Appartment-preview .detail_loc {
    margin: 40px;
    margin-bottom: 0;
  }
}
.Page-account .Reservations .Appartment-preview .detail_loc .headline, .Page-creation .Reservations .Appartment-preview .detail_loc .headline {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  border: 0;
}
.Page-account .Reservations .Appartment-preview .detail_loc .headline:before, .Page-creation .Reservations .Appartment-preview .detail_loc .headline:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 100%;
  left: 60px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
}
.Page-account .Reservations .Appartment-preview .detail_loc .headline .detail__toggle, .Page-creation .Reservations .Appartment-preview .detail_loc .headline .detail__toggle {
  padding: 0px 0px 1px;
  border: 0;
  border-bottom: 1px solid #231f20;
  background: none;
}
.Page-account .Reservations .Appartment-preview .detail_loc .headline .detail__toggle > span, .Page-creation .Reservations .Appartment-preview .detail_loc .headline .detail__toggle > span {
  display: none;
}
.Page-account .Reservations .Appartment-preview .detail_loc .headline .detail__toggle > span:nth-child(1), .Page-creation .Reservations .Appartment-preview .detail_loc .headline .detail__toggle > span:nth-child(1) {
  display: inline-block;
}
.Page-account .Reservations .Appartment-preview .detail_loc .headline .detail__toggle.active > span, .Page-creation .Reservations .Appartment-preview .detail_loc .headline .detail__toggle.active > span {
  display: none;
}
.Page-account .Reservations .Appartment-preview .detail_loc .headline .detail__toggle.active > span + span, .Page-creation .Reservations .Appartment-preview .detail_loc .headline .detail__toggle.active > span + span {
  display: inline-block !important;
}
.Page-account .Reservations .Appartment-preview .detail_loc .inner, .Page-creation .Reservations .Appartment-preview .detail_loc .inner {
  display: block;
  overflow: hidden;
  height: 0px;
  padding: 0px;
}
.Page-account .Reservations .Appartment-preview .detail_loc .inner ul, .Page-creation .Reservations .Appartment-preview .detail_loc .inner ul {
  list-style-type: none;
  margin: 0;
  padding: 20px;
}
@media screen and (min-width: 768px) {
  .Page-account .Reservations .Appartment-preview .detail_loc .inner ul, .Page-creation .Reservations .Appartment-preview .detail_loc .inner ul {
    padding: 40px;
  }
}
.Page-account .Reservations .Appartment-preview .detail_loc .inner ul li, .Page-creation .Reservations .Appartment-preview .detail_loc .inner ul li {
  padding: 12px 0px;
  font-size: 1.6rem;
}
@media screen and (min-width: 768px) {
  .Page-account .Reservations .Appartment-preview .detail_loc .inner ul li, .Page-creation .Reservations .Appartment-preview .detail_loc .inner ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.Page-account .Reservations .Appartment-preview .detail_loc .inner ul li.total, .Page-creation .Reservations .Appartment-preview .detail_loc .inner ul li.total {
  margin-top: 40px;
  padding-top: 20px;
}
.Page-account .Reservations .Appartment-preview .detail_loc .inner ul li.total ~ li, .Page-creation .Reservations .Appartment-preview .detail_loc .inner ul li.total ~ li {
  border: 0;
}
.Page-account .Reservations .Appartment-preview .detail_loc .inner ul li.total .price, .Page-creation .Reservations .Appartment-preview .detail_loc .inner ul li.total .price {
  font-size: 3.2rem;
}
.Page-account .Reservations .Appartment-preview .detail_loc .inner ul li + li, .Page-creation .Reservations .Appartment-preview .detail_loc .inner ul li + li {
  border-top: 1px solid #f8f8f8;
}
.Page-account .Reservations .Appartment-preview .detail_loc .inner H4, .Page-creation .Reservations .Appartment-preview .detail_loc .inner H4 {
  font-size: 2.4rem;
}
.Page-account .Reservations .Appartment-preview .detail_loc .inner .price, .Page-creation .Reservations .Appartment-preview .detail_loc .inner .price {
  white-space: nowrap;
  font-size: 1.8rem;
  display: block;
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .Page-account .Reservations .Appartment-preview .detail_loc .inner .price, .Page-creation .Reservations .Appartment-preview .detail_loc .inner .price {
    margin-left: 30px;
    margin-top: 0;
  }
}
.Page-account .Reservations .Appartment-preview .detail_loc .inner ul > i, .Page-creation .Reservations .Appartment-preview .detail_loc .inner ul > i {
  display: block;
  font-size: 1.6rem;
  margin-top: 20px;
  background: #f8f8f8;
  padding: 20px;
}
.Page-account .Reservations .Appartment-preview .actions, .Page-creation .Reservations .Appartment-preview .actions {
  white-space: nowrap;
  text-align: left;
  margin-top: 20px;
}
@media screen and (min-width: 992px) {
  .Page-account .Reservations .Appartment-preview .actions, .Page-creation .Reservations .Appartment-preview .actions {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    margin-top: 0px;
  }
}
.Page-account .Reservations .Appartment-preview .actions a, .Page-creation .Reservations .Appartment-preview .actions a {
  margin: 0;
  margin-bottom: 10px;
}
.Page-account .Reservations .Appartment-preview .actions a:last-child, .Page-creation .Reservations .Appartment-preview .actions a:last-child {
  margin-bottom: 0;
}
.Page-account .Locations > .container, .Page-creation .Locations > .container {
  padding-top: 40px !important;
}
.Page-account .Locations .Appartments-list, .Page-creation .Locations .Appartments-list {
  margin: 0;
}
.Page-account .Locations .Appartment-title, .Page-creation .Locations .Appartment-title {
  margin-bottom: 0;
}
.Page-account .Locations .btn__container, .Page-creation .Locations .btn__container {
  margin-top: 20px;
}
.Page-account .Locations .btn__container + .btn__container, .Page-creation .Locations .btn__container + .btn__container {
  margin-top: 5px;
}
.Page-account .Locations .btn__container .btn, .Page-creation .Locations .btn__container .btn {
  margin: 0;
}
.Page-account .Page-footer, .Page-creation .Page-footer {
  margin-top: 0px;
}
.Page-account .Page-footer h3, .Page-creation .Page-footer h3, .Page-account .Page-footer .Page-contact form[name=form_contact] .row .col legend, .Page-creation .Page-footer .Page-contact form[name=form_contact] .row .col legend, .Page-contact form[name=form_contact] .row .col .Page-account .Page-footer legend, .Page-contact form[name=form_contact] .row .col .Page-creation .Page-footer legend, .Page-account .Page-footer .Login form fieldset legend, .Page-creation .Page-footer .Login form fieldset legend, .Page-account .Login form fieldset .Page-footer legend, .Page-creation .Login form fieldset .Page-footer legend {
  margin-bottom: 10px;
}
.Page-account .Page-footer p, .Page-creation .Page-footer p, .Page-account .Page-footer .noresult, .Page-creation .Page-footer .noresult {
  font-size: 1.6rem;
}
.Page-account .Page-footer .btn, .Page-creation .Page-footer .btn {
  margin-top: 20px;
}
.Page-account .Recovery > .container, .Page-creation .Recovery > .container {
  padding-top: 60px !important;
}

.Page-creation .Page-header p, .Page-creation .Page-header .noresult {
  max-width: 100% !important;
}
.Page-creation .Login form {
  width: 100% !important;
  max-width: 100% !important;
}
.Page-creation .Login form .form-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.Page-creation .Create > .container {
  padding-top: 80px !important;
}

.Page-reservation, .Page-paiement {
  padding-bottom: 0 !important;
  background: #ffffff !important;
  border-bottom: 1px solid #e9e9e9;
}
.Page-reservation .Page-header > .container, .Page-paiement .Page-header > .container {
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .Page-reservation .Page-header > .container, .Page-paiement .Page-header > .container {
    padding-top: 60px;
    padding-bottom: 120px;
  }
}
@media screen and (min-width: 768px) {
  .Page-reservation section > .container, .Page-paiement section > .container {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
}
@media screen and (max-width: 669px) {
  .Page-reservation .Appartment-recap > .container, .Page-paiement .Appartment-recap > .container {
    padding-top: 0px !important;
  }
}
@media screen and (max-width: 767px) {
  .Page-reservation .Appartment-recap > .container, .Page-paiement .Appartment-recap > .container {
    display: block !important;
  }
}
.Page-reservation .Appartment-recap .visuel-container, .Page-paiement .Appartment-recap .visuel-container {
  width: calc(100% + 80px);
  position: relative;
  overflow: hidden;
  z-index: 200;
  margin-bottom: 40px;
  margin-left: -40px;
}
@media screen and (min-width: 670px) {
  .Page-reservation .Appartment-recap .visuel-container, .Page-paiement .Appartment-recap .visuel-container {
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (min-width: 992px) {
  .Page-reservation .Appartment-recap .visuel-container, .Page-paiement .Appartment-recap .visuel-container {
    width: 100%;
    max-width: 35%;
    margin-bottom: 0px;
    margin-left: 0;
  }
}
.Page-reservation .Appartment-recap .visuel-container .notif, .Page-paiement .Appartment-recap .visuel-container .notif {
  position: absolute;
  top: 20px;
  left: 20px;
}
.Page-reservation .Appartment-recap .visuel-container:after, .Page-paiement .Appartment-recap .visuel-container:after {
  content: "";
  display: block;
  padding-top: 70%;
}
.Page-reservation .Appartment-recap .visuel-container img, .Page-paiement .Appartment-recap .visuel-container img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: auto;
  max-width: none;
}
.Page-reservation .Appartment-recap .content, .Page-paiement .Appartment-recap .content {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .Page-reservation .Appartment-recap .content, .Page-paiement .Appartment-recap .content {
    max-width: 65%;
    padding-left: 60px;
  }
}
@media screen and (min-width: 1200px) {
  .Page-reservation .Appartment-recap .content, .Page-paiement .Appartment-recap .content {
    padding-left: 80px;
  }
}
.Page-reservation .Appartment-recap .content .Appartment-title, .Page-paiement .Appartment-recap .content .Appartment-title {
  text-align: left !important;
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .Page-reservation .Appartment-recap .content .Appartment-title, .Page-paiement .Appartment-recap .content .Appartment-title {
    font-size: 5rem;
  }
}
.Page-reservation .Appartment-recap .content .recap-list, .Page-paiement .Appartment-recap .content .recap-list {
  margin-top: 40px;
  margin-bottom: -30px;
}
.Page-reservation .Appartment-recap .content .recap-list .item, .Page-paiement .Appartment-recap .content .recap-list .item {
  margin-bottom: 30px;
}
.Page-reservation .Appartment-recap .content .recap-list .item-value, .Page-paiement .Appartment-recap .content .recap-list .item-value {
  font-size: 2.4rem;
}
.Page-reservation .Appartment-recap .content .btn, .Page-paiement .Appartment-recap .content .btn {
  display: block;
}
@media screen and (min-width: 670px) {
  .Page-reservation .Appartment-recap .content .btn, .Page-paiement .Appartment-recap .content .btn {
    display: inline-block;
  }
}
@media screen and (min-width: 992px) {
  .Page-reservation .Appartment-recap.recap .visuel-container, .Page-paiement .Appartment-recap.recap .visuel-container {
    max-width: 45%;
  }
}
@media screen and (min-width: 992px) {
  .Page-reservation .Appartment-recap.recap .content, .Page-paiement .Appartment-recap.recap .content {
    max-width: 55%;
  }
}
.Page-reservation .retour_paiement, .Page-paiement .retour_paiement {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 120px;
}
.Page-reservation .retour_paiement h1, .Page-paiement .retour_paiement h1 {
  font-size: 6rem;
  margin: 0;
}
.Page-reservation .retour_paiement p a, .Page-reservation .retour_paiement .noresult a, .Page-paiement .retour_paiement p a, .Page-paiement .retour_paiement .noresult a {
  color: #231f20;
  text-decoration: none;
}

.Reservation-nav {
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.Reservation-nav li {
  display: inline-block;
  position: relative;
}
.Reservation-nav li.active span {
  display: block !important;
}
.Reservation-nav li.active ~ li {
  opacity: 0.3;
}
.Reservation-nav li.active ~ li span {
  display: none;
}
@media screen and (min-width: 768px) {
  .Reservation-nav li.active ~ li span {
    display: block;
  }
}
.Reservation-nav li:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 30px;
  background: #231f20;
  display: inline-block;
  vertical-align: middle;
}
.Reservation-nav li + li:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 1px;
  margin: 0 14px;
  background: #727272;
}
@media screen and (min-width: 768px) {
  .Reservation-nav li + li:before {
    width: 120px;
  }
}
.Reservation-nav span {
  position: absolute;
  top: 100%;
  right: 8px;
  white-space: nowrap;
  transform: translateX(50%);
  margin-top: 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  display: none;
}
@media screen and (min-width: 768px) {
  .Reservation-nav span {
    display: block;
  }
}
.Reservation-dates, .Reservation-personnes {
  text-align: center;
}
.Reservation-dates #erreur_dates_resa, .Reservation-personnes #erreur_dates_resa {
  color: #f01414;
  margin-bottom: 20px;
  display: none;
}
.Reservation-dates #erreur_dates_resa + .wrapper .heure, .Reservation-personnes #erreur_dates_resa + .wrapper .heure {
  opacity: 0.3;
  pointer-events: none;
}
.Reservation-dates .datepickers, .Reservation-personnes .datepickers {
  border: 0;
}
.Reservation-dates .wrapper, .Reservation-personnes .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  max-width: 770px;
  margin: 0 auto;
}
@media screen and (min-width: 992px) {
  .Reservation-dates .wrapper, .Reservation-personnes .wrapper {
    flex-wrap: nowrap;
  }
}
.Reservation-dates .select, .Reservation-personnes .select {
  flex-grow: 2;
  position: relative;
}
.Reservation-dates .select .ti, .Reservation-personnes .select .ti {
  position: absolute;
  bottom: 100%;
  margin-bottom: 5px;
  left: 5px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
}
.Reservation-dates .select:first-child:before, .Reservation-personnes .select:first-child:before {
  display: none;
}
.Reservation-dates .select:before, .Reservation-personnes .select:before {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -15px;
  content: "";
  display: block;
  height: 30px;
  width: 1px;
  background: #d3d3d3;
}
.Reservation-dates .select .chzn-drop, .Reservation-personnes .select .chzn-drop {
  width: 100% !important;
}
.Reservation-dates .select .chzn-container, .Reservation-personnes .select .chzn-container {
  width: 100% !important;
  height: 100%;
  border: 0;
}
.Reservation-dates .select .chzn-container .chzn-single, .Reservation-personnes .select .chzn-container .chzn-single {
  display: block;
  height: 50px;
  line-height: 50px;
  box-shadow: none !important;
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .Reservation-dates .select .chzn-container .chzn-single, .Reservation-personnes .select .chzn-container .chzn-single {
    height: 70px;
    line-height: 70px;
    padding: 0 30px;
  }
}
.Reservation-dates .select .chzn-container .chzn-single span, .Reservation-personnes .select .chzn-container .chzn-single span {
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: none;
  margin: 0;
  margin-right: 30px;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .Reservation-dates .select .chzn-container .chzn-single span, .Reservation-personnes .select .chzn-container .chzn-single span {
    font-size: 2rem;
  }
}
.Reservation-dates .select .chzn-container .chzn-single div, .Reservation-personnes .select .chzn-container .chzn-single div {
  right: 20px;
}
.Reservation-dates .select .chzn-container .chzn-single div > b, .Reservation-personnes .select .chzn-container .chzn-single div > b {
  background-size: 12px auto;
}
.Reservation-dates .legend, .Reservation-dates .info_enfants, .Reservation-personnes .legend, .Reservation-personnes .info_enfants {
  margin-top: 10px;
}
@media screen and (max-width: 991px) {
  .Reservation-dates .select:before {
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 1px !important;
    margin-top: 0 !important;
  }
}
.Reservation-services h2 {
  margin-bottom: 0 !important;
}
.Reservation-services h2 + p, .Reservation-services h2 + .noresult {
  margin: 0 auto 60px;
  text-align: center;
  color: #231f20;
}
.Reservation-services .list-services .service-item {
  margin-bottom: 10px;
  position: relative;
}
.Reservation-services .list-services .service-item input[type=checkbox] {
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  opacity: 0;
}
.Reservation-services .list-services .service-item input[type=checkbox]:checked + label {
  background: #231f20;
  color: #ffffff;
}
.Reservation-services .list-services .service-item input[type=checkbox]:checked + label .custom-checkbox {
  background: #ffffff;
  border-color: #ffffff;
}
.Reservation-services .list-services .service-item input[type=checkbox]:checked + label .custom-checkbox svg {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
.Reservation-services .list-services .service-item input[type=number] {
  border: 1px solid #d3d3d3;
  position: absolute;
  top: 15px;
  left: 18px;
  bottom: 15px;
  width: 45px;
  border-radius: 3px;
  font-size: 2rem;
  text-align: center;
}
.Reservation-services .list-services .service-item input[type=number] + label {
  padding-left: 80px;
}
.Reservation-services .list-services .service-item input[type=number].filled + label {
  background: #231f20;
  color: #ffffff;
}
.Reservation-services .list-services .service-item input[type=number].filled + label .custom-checkbox {
  background: #ffffff;
  border-color: #ffffff;
}
.Reservation-services .list-services .service-item input[type=number].filled + label .custom-checkbox svg {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
.Reservation-services .list-services .service-item .custom-checkbox {
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #d3d3d3;
  margin-right: 20px;
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
  .Reservation-services .list-services .service-item .custom-checkbox {
    margin-bottom: 0;
  }
}
.Reservation-services .list-services .service-item .custom-checkbox svg {
  width: 10px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Reservation-services .list-services .service-item label {
  display: block;
  font-size: 1.6rem;
  letter-spacing: 0;
  text-transform: none;
  padding: 20px;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  margin: 0;
  cursor: pointer;
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
@media screen and (min-width: 768px) {
  .Reservation-services .list-services .service-item label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    font-size: 2rem;
  }
}
.Reservation-services .list-services .service-item label > span:not(.price):not(.custom-checkbox) {
  margin-right: 30px;
}
.Reservation-services .list-services .service-item a {
  font-size: 1.3rem;
  letter-spacing: 0;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
}
.Reservation-services .list-services .service-item a:hover .tooltip {
  visibility: visible;
  transform: translate(-50%, 0%);
  opacity: 1;
}
.Reservation-services .list-services .service-item a .tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 10%);
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 400px;
  border-radius: 3px;
  white-space: normal;
  margin-top: 10px;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 100;
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.Reservation-services .list-services .service-item a .tooltip p, .Reservation-services .list-services .service-item a .tooltip .noresult {
  width: 100%;
}
.Reservation-services .list-services .service-item .price {
  font-size: 2.4rem;
  font-family: "orpheuspro", serif;
  width: 100%;
  text-align: left;
  margin-top: 20px;
  display: block;
}
@media screen and (min-width: 768px) {
  .Reservation-services .list-services .service-item .price {
    margin-left: auto;
    width: auto;
    text-align: right;
    margin-top: 0;
  }
}
.Reservation-services .list-services .service-item .price sup {
  font-size: 1.6rem;
}
.Reservation-services .list-services .service-item .price .ttc {
  font-size: 1.7rem;
  font-family: "macho", sans-serif;
  margin-left: 5px;
}
.Reservation-total {
  border-top: 1px solid #e9e9e9;
}
@media screen and (min-width: 1200px) {
  .Reservation-total .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.Reservation-total h2 {
  text-align: left !important;
}
.Reservation-total .total-line {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
}
.Reservation-total .total-line + .total-line, .Reservation-total .total-line + .legend, .Reservation-total .Reservation-dates .total-line + .info_enfants, .Reservation-dates .Reservation-total .total-line + .info_enfants, .Reservation-total .Reservation-personnes .total-line + .info_enfants, .Reservation-personnes .Reservation-total .total-line + .info_enfants {
  border-top: 1px solid #e9e9e9;
  padding-top: 12px;
}
.Reservation-total .total-line + .legend, .Reservation-total .Reservation-dates .total-line + .info_enfants, .Reservation-dates .Reservation-total .total-line + .info_enfants, .Reservation-total .Reservation-personnes .total-line + .info_enfants, .Reservation-personnes .Reservation-total .total-line + .info_enfants {
  color: #231f20;
  font-size: 1.3rem;
}
.Reservation-total .total-line.hidden {
  display: none;
}
.Reservation-total .total-line:last-child {
  border-bottom: 0;
}
.Reservation-total .total-line > span {
  font-size: 1.4rem;
}
@media screen and (min-width: 768px) {
  .Reservation-total .total-line > span {
    font-size: 1.7rem;
  }
}
.Reservation-total .total-line > span:not(.price) {
  margin-right: 40px;
  margin-top: 3px;
}
.Reservation-total .legend, .Reservation-total .Reservation-dates .info_enfants, .Reservation-dates .Reservation-total .info_enfants, .Reservation-total .Reservation-personnes .info_enfants, .Reservation-personnes .Reservation-total .info_enfants {
  display: block;
  font-size: 1.2rem;
  color: #727272;
  margin-top: 5px;
}
.Reservation-total .checkboxs {
  margin-top: 30px;
}
.Reservation-total .checkboxs a {
  text-decoration: underline;
}
.Reservation-total .content {
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .Reservation-total .content {
    width: calc(100% - 340px);
    max-width: 60%;
  }
}
.Reservation-total .price {
  white-space: nowrap;
  margin-left: auto;
  font-size: 2.4rem !important;
  font-family: "orpheuspro", serif;
}
.Reservation-total .price sup {
  font-size: 1.6rem;
}
.Reservation-total .price .ttc {
  font-size: 1.7rem;
  font-family: "macho", sans-serif;
  margin-left: 5px;
}
.Reservation-total .total {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.Reservation-total .total:first-child {
  margin-top: 0;
}
.Reservation-total .total .price {
  font-size: 3.2rem !important;
}
@media screen and (min-width: 768px) {
  .Reservation-total .total .price {
    font-size: 4.4rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .Reservation-total .total .price {
    font-size: 5.6rem !important;
  }
}
.Reservation-total .total .price sup {
  font-size: 1.8rem !important;
}
@media screen and (min-width: 768px) {
  .Reservation-total .total .price sup {
    font-size: 2.6rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .Reservation-total .total .price sup {
    font-size: 3.2rem !important;
  }
}
.Reservation-total .boutons {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .Reservation-total .boutons {
    text-align: right;
  }
}
.Reservation-total #erreur {
  color: #f01414;
  display: block !important;
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .Reservation-total.sub .total-line > span {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1200px) {
  .Reservation-total.sub .total-line > span {
    font-size: 2rem;
  }
}
.Reservation h2 {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .Reservation h2 {
    font-size: 3.6rem;
    margin-bottom: 60px;
  }
}

.Page-plan h1 {
  font-size: 8rem;
  margin-bottom: 40px;
}
.Page-plan h3, .Page-plan .Page-contact form[name=form_contact] .row .col legend, .Page-contact form[name=form_contact] .row .col .Page-plan legend, .Page-plan .Page-account .Login form fieldset legend, .Page-account .Login form fieldset .Page-plan legend, .Page-plan .Page-creation .Login form fieldset legend, .Page-creation .Login form fieldset .Page-plan legend {
  font-size: 3rem;
  margin-bottom: 20px;
}
.Page-plan ul {
  padding-left: 30px;
}
.Page-plan ul + h3, .Page-plan .Page-contact form[name=form_contact] .row .col ul + legend, .Page-contact form[name=form_contact] .row .col .Page-plan ul + legend, .Page-plan .Page-account .Login form fieldset ul + legend, .Page-account .Login form fieldset .Page-plan ul + legend, .Page-plan .Page-creation .Login form fieldset ul + legend, .Page-creation .Login form fieldset .Page-plan ul + legend {
  margin-top: 40px;
}
.Page-plan ul li {
  list-style-type: circle;
}
.Page-plan ul a {
  font-weight: 400;
}
.Page-plan ul a:hover {
  text-decoration: underline;
}
.Page-plan ul ul {
  margin: 10px auto 20px;
}
.Page-plan .separ {
  margin: 40px auto;
  height: 1px;
  background: #dfdfdf;
}